import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import { useAuth } from '../../core/contexts/useAuthState';
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  Alert,
  CircularProgress,
  DialogContentText,
  DialogActions,
  Tooltip,
  Chip
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import UserFormPage from './UserFormPage';
import TravelLoading from '../../shared/common/TravelLoading';

import { usePermissions } from '../../hooks/usePermissions';
import {
  getAgencyUsers,
  deleteAgencyUser,
  UserRoles,
  reactivateUser,
  getAgencyLimits
} from './services/UserManagementService';

const UserManagementPage = () => {
  const { checkPermission } = usePermissions();
  const navigate = useNavigate();
  const { currentUser, isOwner } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inactivateDialog, setInactivateDialog] = useState({
    open: false,
    user: null
  });
  const [agencyLimits, setAgencyLimits] = useState({
    current: 0,
    limit: 3
  });

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const canViewUsers = checkPermission('users', 'view');
  const canCreateUsers = checkPermission('users', 'create');
  const canEditUsers = checkPermission('users', 'edit');
  const canDeleteUsers = checkPermission('users', 'delete');

  useEffect(() => {
    if (currentUser?.userData?.agency?.id) {
      loadUsers();
    }
  }, [currentUser]);

  useEffect(() => {
    const loadAgencyData = async () => {
      try {
        if (currentUser?.userData?.agency?.id) {
          const limits = await getAgencyLimits(currentUser.userData.agency.id);
          setAgencyLimits(limits);
        }
      } catch (error) {
        showError(error.message);
      }
    };

    loadAgencyData();
  }, [currentUser, users]);
  const isLimitReached = agencyLimits.current >= agencyLimits.limit;

  const loadUsers = async () => {
    try {
      setLoading(true);
      if (currentUser?.userData?.agency?.id) {
        const agencyUsers = await getAgencyUsers(currentUser.userData.agency.id);
        setUsers(agencyUsers);
      }
    } catch (error) {
      showError('Erro ao carregar usuários: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Função helper para mostrar mensagens de sucesso
  const showSuccess = (message) => {
    setSnackbarState({
      open: true,
      message,
      severity: 'success'
    });
  };

  const showError = (message) => {
    setSnackbarState({
      open: true,
      message,
      severity: 'error'
    });
  };

  const handleUserUpdate = async (success, message) => {
    if (success) {
      await loadUsers();
      showSuccess(message);
    } else {
      showError(message);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarState(prev => ({
      ...prev,
      open: false
    }));
  };

  const openInactivateDialog = (user) => {
    setInactivateDialog({
      open: true,
      user
    });
  };

  const closeInactivateDialog = () => {
    setInactivateDialog({
      open: false,
      user: null
    });
  };

  const handleInactivateUser = async () => {
    try {
      setLoading(true);
      await deleteAgencyUser(currentUser.userData.agency.id, inactivateDialog.user.id);
      await loadUsers();
      showSuccess('Usuário inativado com sucesso!');
      closeInactivateDialog();
    } catch (error) {
      showError('Erro ao inativar usuário: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleReactivateUser = async (userId) => {
    try {
      setLoading(true);
      await reactivateUser(currentUser.userData.agency.id, userId);
      await loadUsers();
      showSuccess('Usuário reativado com sucesso!');
    } catch (error) {
      showError('Erro ao reativar usuário: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const roleTranslations = {
    owner: 'Proprietário',
    admin: 'Administrador',
    manager: 'Gerente',
    driver: 'Motorista',
    guide: 'Guia',
  };

  const statusTranslations = {
    active: 'Ativo',
    inactive: 'Inativo',
    pending: 'Pendente'
  };

  const handleEditUser = (user) => {
    navigate(`/usuarios/${user.id}/editar`);
  };

  return (
    <Box sx={{ p: 2 }}>
      {canViewUsers ? (
        <>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography variant="h6" component="div">
                Usuários
              </Typography>
              {canCreateUsers && !isLimitReached && (
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => navigate('/usuarios/novo')}
                  sx={{ borderRadius: '50px' }}
                  disabled={loading}
                >
                  Adicionar
                </Button>
              )}
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: 'background.paper',
                padding: 1,
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider'
              }}>
                <Typography variant="body2" color="text.secondary">
                  Usuários ativos: {agencyLimits.current} de {agencyLimits.limit}
                </Typography>
                <Tooltip title="Limite de usuários ativos permitidos para sua conta">
                  <InfoIcon fontSize="small" color="action" />
                </Tooltip>
              </Box>

              {isLimitReached && (
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  backgroundColor: 'warning.light',
                  padding: 1,
                  borderRadius: 1,
                }}>
                  <Typography variant="body2" color="warning.dark">
                    Limite de usuários atingido!
                  </Typography>
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<WhatsAppIcon />}
                    onClick={() => window.open('https://wa.me/554288333458?text=Ol%C3%A1%2C%20gostaria%20de%20aumentar%20meu%20numero%20de%20usuarios%21')}
                    sx={{
                      borderRadius: '50px',
                    }}
                  >
                    Solicitar mais usuários
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          <Card>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell>Cargo</TableCell>
                  <TableCell>Status</TableCell>
                  {(canEditUsers || canDeleteUsers) && <TableCell align="right">Ações</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow
                    key={user.id}
                    sx={{
                      '&:hover': { backgroundColor: 'action.hover' },
                      transition: 'background-color 0.2s'
                    }}
                  >
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {roleTranslations[user.role] || user.role}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={statusTranslations[user.metadata.status]}
                        color={user.metadata.status === 'active' ? 'success' : 'default'}
                        size="small"
                        variant="outlined"
                      />
                    </TableCell>
                    {(canEditUsers || canDeleteUsers) && (
                      <TableCell align="right">
                        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                          {user.metadata.status === 'active' ? (
                            <>
                              {canEditUsers && (
                                <Tooltip title="Editar usuário">
                                  <IconButton
                                    size="small"
                                    onClick={() => handleEditUser(user)}
                                    disabled={loading}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              )}

                              {canDeleteUsers && user.role !== UserRoles.OWNER && (
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="error"
                                  onClick={() => openInactivateDialog(user)}
                                  disabled={loading}
                                  sx={{ borderRadius: '50px' }}
                                >
                                  Inativar
                                </Button>
                              )}
                            </>
                          ) : (
                            <Button
                              size="small"
                              variant="outlined"
                              color="success"
                              onClick={() => handleReactivateUser(user.id)}
                              disabled={loading}
                              sx={{ borderRadius: '50px' }}
                            >
                              Reativar
                            </Button>
                          )}
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {users.length === 0 && !loading && (
                  <TableRow>
                    <TableCell colSpan={5} align="center" sx={{ py: 3 }}>
                      <Typography color="text.secondary">
                        Nenhum usuário encontrado
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Card>
        </>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Typography color="text.secondary">
            Você não tem permissão para visualizar usuários
          </Typography>
        </Box>
      )}

      <Routes>
        <Route
          path="/usuarios/novo"
          element={
            <UserFormPage
              onSubmitComplete={(success, message) => {
                handleUserUpdate(success, message);
                if (success) {
                  loadUsers();
                }
              }}
            />
          }
        />
        <Route
          path="/usuarios/:userId/editar"
          element={
            <UserFormPage
              onSubmitComplete={(success, message) => {
                handleUserUpdate(success, message);
                if (success) {
                  loadUsers();
                }
              }}
            />
          }
        />
      </Routes>

      {/* Dialog de Confirmação de Inativação */}
      <Dialog
        open={inactivateDialog.open}
        onClose={closeInactivateDialog}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          Confirmar Inativação
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja inativar o usuário <strong>{inactivateDialog.user?.name}</strong>?
            <br /><br />
            O usuário não poderá acessar o sistema até ser reativado.
            {inactivateDialog.user?.role === 'admin' && (
              <Box sx={{ mt: 2, color: 'warning.main' }}>
                <strong>Atenção:</strong> Você está inativando um usuário administrador.
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={closeInactivateDialog}
            variant="outlined"
            disabled={loading}
            sx={{ borderRadius: '50px' }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleInactivateUser}
            variant="contained"
            color="error"
            disabled={loading}
            sx={{ borderRadius: '50px' }}
          >
            {loading ? <CircularProgress size={24} /> : 'Confirmar Inativação'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarState.severity} sx={{ width: '100%' }}>
          {snackbarState.message}
        </Alert>
      </Snackbar>

      {loading && <TravelLoading disableAlignItems />}
    </Box>
  );
};

export default UserManagementPage;