import { auth, db } from '../../../firebaseConfig';
import { doc, updateDoc, getDoc, setDoc, collection, query, where, writeBatch, getDocs, limit } from 'firebase/firestore';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, sendPasswordResetEmail, fetchSignInMethodsForEmail, sendEmailVerification, updateProfile as firebaseUpdateProfile, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { createDefaultResourcesForAgency } from '../../MyAccount/services/PopulateNewAgency';

// Enum para os tipos de registro
export const RegistrationType = {
  PERSONAL: 'personal',
  BUSINESS: 'business'
};

// Função para registrar um novo usuário com email e senha
export const registerWithEmailPassword = async (registrationData) => {
  const {
    email,
    password,
    name,
    phone,
    type,
    agencyName
  } = registrationData;

  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    if (!user) {
      throw new Error('Failed to create user.');
    }

    await firebaseUpdateProfile(user, { displayName: name });

    const finalAgencyName = type === RegistrationType.PERSONAL
      ? `${name}`
      : agencyName;

    const startDate = new Date();
    const trialEndDate = new Date(startDate);
    trialEndDate.setDate(startDate.getDate() + 15);

    // Create agency document
    const agencyRef = doc(db, 'agencies', user.uid);
    await setDoc(agencyRef, {
      name: finalAgencyName,
      ownerId: user.uid,
      status: 'active',
      userLimit: 1,
      subscription: {
        planId: 'trial',
        startDate: startDate.toISOString(),
        endDate: trialEndDate.toISOString(),
        status: 'active'
      },
      metadata: {
        createdAt: startDate.toISOString(),
        updatedAt: startDate.toISOString(),
        registrationType: type
      },
      stats: {
        totalUsers: 1,
        totalVehicles: 0,
        totalTravels: 0,
        lastActivityAt: startDate.toISOString()
      }
    });

    // Create user document
    const userDocRef = doc(db, 'users', user.uid);
    await setDoc(userDocRef, {
      email: user.email,
      name: name,
      phone: phone,
      type: 'agency_owner',
      agencyId: user.uid,
      isApproved: true,
      masterPassword: null,
      masterPasswordActive: false,
      metadata: {
        createdAt: new Date().toISOString(),
        lastLogin: new Date().toISOString(),
        status: 'active',
        registrationType: type,
        trialEndsAt: trialEndDate.toISOString()
      }
    });

    // Add user as agency member with admin role
    const memberRef = doc(db, `agencies/${user.uid}/members`, user.uid);
    await setDoc(memberRef, {
      roleId: 'owner',
      customPermissions: null,
      metadata: {
        addedAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        status: 'active',
        lastAccess: new Date().toISOString()
      }
    });

    await sendEmailVerification(user);

    try {
      await createDefaultResourcesForAgency(user.uid);
    } catch (defaultError) {
      console.error('Failed creating defaults:', defaultError);
    }

    return {
      user,
      agency: {
        id: user.uid,
        name: finalAgencyName,
        type
      }
    };

  } catch (error) {
    if (auth.currentUser) {
      try {
        await auth.currentUser.delete();
      } catch (deleteError) {
        console.error('Error cleaning up user:', deleteError);
      }
    }
    throw new Error(mapFirebaseError(error));
  }
};

// Função para lidar com o login do usuário
export const loginWithEmailPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    if (!user) throw new Error('Error authenticating user');
    if (!user.emailVerified) {
      await signOut(auth);
      return { status: 'unverified_email' };
    }

    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      await signOut(auth);
      throw new Error('Usuário não encontrado');
    }

    const userData = userDoc.data();

    if (userData.agencyId) {
      const agencyRef = doc(db, 'agencies', userData.agencyId);
      const agencyDoc = await getDoc(agencyRef);

      if (!agencyDoc.exists()) {
        console.error('Agency document not found');
        await signOut(auth);
        throw new Error('Agency data not found');
      }

      const agencyData = agencyDoc.data();

      if (agencyData.status !== 'active') {
        await signOut(auth);
        return { status: 'agency_inactive' };
      }

      if (!userData.isApproved) {
        await signOut(auth);
        return { status: 'subscription_expired' };
      }

      const trialStatus = checkTrialStatus(agencyData);
      if (!trialStatus.isValid) {
        try {
          const batch = writeBatch(db);
          batch.update(agencyRef, {
            'subscription.status': 'expired'
          });

          const usersRef = collection(db, 'users');
          const agencyUsersQuery = query(usersRef, where('agencyId', '==', userData.agencyId));
          const agencyUsersSnapshot = await getDocs(agencyUsersQuery);

          agencyUsersSnapshot.docs.forEach(userDoc => {
            batch.update(userDoc.ref, { isApproved: false });
          });

          await batch.commit();
          await signOut(auth);
          return { status: 'subscription_expired' };
        } catch (error) {
          await signOut(auth);
          return { status: 'subscription_expired' };
        }
      }

      if (agencyData.subscription?.status === 'expired') {
        await signOut(auth);
        return { status: 'subscription_expired' };
      }

      const memberRef = doc(db, `agencies/${userData.agencyId}/members`, user.uid);
      const memberDoc = await getDoc(memberRef);

      if (!memberDoc.exists()) {
        await signOut(auth);
        throw new Error('Member data not found');
      }

      const memberData = memberDoc.data();

      return {
        status: 'success',
        user,
        userData: {
          ...userData,
          agency: agencyData,
          role: memberData.roleId,
          permissions: memberData.customPermissions
        }
      };
    }

    return {
      status: 'success',
      user,
      userData
    };

  } catch (error) {
    console.error('Login error:', error);
    throw new Error(mapFirebaseError(error));
  }
};

// Função auxiliar para verificar status do trial
const checkTrialStatus = (agencyData) => {
  if (agencyData.subscription?.planId !== 'trial') {
    return { isValid: true };
  }

  const trialEndDate = new Date(agencyData.subscription.endDate);
  const now = new Date();

  if (now > trialEndDate) {
    return {
      isValid: false,
      status: 'trial_expired'
    };
  }

  return { isValid: true };
};

// Função para fazer logout do usuário atual
export const logout = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    throw new Error(mapFirebaseError(error));
  }
};

export const checkEmailExists = async (email) => {
  try {
    const methods = await fetchSignInMethodsForEmail(auth, email);
    return methods.length > 0; // Retorna true se o email já existe
  } catch (error) {
    console.error('Erro ao verificar email:', error);
    throw new Error(mapFirebaseError(error));
  }
};

export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    throw new Error(mapFirebaseError(error));
  }
};

// Função personalizada para atualizar o perfil do usuário
export const updateUserProfile = async (profileUpdates) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('Usuário não está autenticado.');

    await firebaseUpdateProfile(user, profileUpdates);

    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      await updateDoc(userDocRef, {
        displayName: profileUpdates.displayName,
        email: user.email,
        isApproved: userDoc.data().isApproved
      });
    } else {
      await setDoc(userDocRef, {
        displayName: profileUpdates.displayName,
        email: user.email,
        isApproved: false
      });
    }

  } catch (error) {
    console.error('Erro ao atualizar perfil do usuário:', error);
    throw new Error(mapFirebaseError(error));
  }
};

// Função para mapear os códigos de erro do Firebase para mensagens mais amigáveis
const mapFirebaseError = (error) => {
  switch (error.code || error.message) {
    case 'auth/user-not-found':
      return 'Usuário não encontrado. Verifique suas credenciais e tente novamente.';
    case 'auth/invalid-credential':
      return 'Credenciais incorretas. Tente novamente.';
    case 'auth/email-already-in-use':
      return 'O endereço de e-mail já está em uso por outra conta.';
    case 'auth/invalid-email':
      return 'O endereço de e-mail não é válido.';
    case 'auth/weak-password':
      return 'A senha é muito fraca. Tente uma senha mais forte.';
    case 'auth/network-request-failed':
      return 'Falha na rede. Verifique sua conexão e tente novamente.';
    case 'auth/email-not-verified':
      return 'Por favor, verifique seu e-mail antes de fazer login. Caso não encontre, verifique sua caixa de spam.';
    case 'trial_expired':
      return 'Sua assinatura expirou. Entre em contato com o suporte para mais informações.';
    default:
      return error.message || 'Ocorreu um erro inesperado. Tente novamente mais tarde.';
  }
};

// Exportação de funções adicionais para atualização de perfil e senha
export {
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
};
