import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Fade, IconButton, Snackbar, Alert, Button, Dialog, DialogContent, Divider, CircularProgress, Menu, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PassengerSelection from './components/PassengerSelection';
import PaymentDetailsForm from './components/PaymentDetailsForm';
import PaymentRecords from './components/PaymentRecords';
import PassengerForm from '../Passenger/PassengerForm';
import SeatChangeDialog from './components/SeatChangeDialog';
import PassengerAllocationHelp from './helps/PassengerAllocationHelp';
import TravelLoading from '../../shared/common/TravelLoading';

import { addOrder, updateOrder, getOrderById, addReservation, updateReservation, getReservationsByTravelId, getAvailableSeats, getReservedSeats, cancelReservation } from './services/OrderService';
import { validarCPF, unformatCPF } from '../../shared/utils/utils';
import { getLayoutById } from '../LayoutPage/services/LayoutService';
import { getVehicleById, getTravelById } from '../Travel/services/TravelService';
import { getAllPassengers } from '../Passenger/services/PassengerService';
import { generateReceiptPDF } from '../../shared/utils/generateReceiptPDF';

const PassengerAllocationPage = ({ isCreating: isCreatingProp }) => {
  const isCreating = isCreatingProp;
  const { id: orderId, travelId } = useParams();
  const theme = useTheme();
  const location = useLocation();
  const { selectedSeats = [], editingReservation, editingOrderId, previousPage } = location.state || {};
  const navigate = useNavigate();

  const [loadingData, setLoadingData] = useState(true);
  const isEditing = !!(editingReservation || editingOrderId);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initialReservation = {
    nomePagador: '',
    cpfPagador: '',
    rgPagador: '',
    metodoPagamento: '',
    valorTotal: '0,00',
    valorPago: '0,00',
    valorRestante: '0,00',
    informacoesAdicionais: ''
  };

  const sortReservationsBySeatNumber = (reservations) => {
    return reservations.sort((a, b) => {
      if (a.numeroAssento === null && b.numeroAssento === null) return 0; // Ambos sem assento
      if (a.numeroAssento === null) return 1; // `a` sem assento vai para o final
      if (b.numeroAssento === null) return -1; // `b` sem assento vai para o final
      return a.numeroAssento - b.numeroAssento; // Ordena pelo número do assento
    });
  };

  const [reservations, setReservations] = useState(() => {
    if (isEditing) {
      const initialReservations = Array.isArray(editingReservation)
        ? editingReservation.map((reservation) => ({
          ...reservation,
          numeroAssento: reservation.numeroAssento ?? null, // Garante o campo com null se ausente
        }))
        : editingReservation
          ? [{ ...editingReservation, numeroAssento: editingReservation.numeroAssento ?? null }]
          : [];
      return sortReservationsBySeatNumber(initialReservations);
    } else if (Array.isArray(selectedSeats)) {
      return selectedSeats.map((seat) => ({
        numeroAssento: seat.number || null, // Inicializa com null
        passengerId: null,
        pagador: false,
      }));
    }
    return [];
  });

  const [detalhesPagamento, setPaymentDetails] = useState(
    editingReservation && Array.isArray(editingReservation) && editingReservation[0].detalhesPagamento
      ? editingReservation[0].detalhesPagamento
      : initialReservation
  );
  const [pagadorEstrangeiro, setPagadorEstrangeiro] = useState(false);
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [passengers, setPassengers] = useState([]);
  const [loadingPassengers, setLoadingPassengers] = useState(false);
  const [existingReservations, setExistingReservations] = useState([]);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [openSeatSelectionDialog, setOpenSeatSelectionDialog] = useState(false);
  const [selectedReservationIndex, setSelectedReservationIndex] = useState(null);
  const [layoutAndar1, setLayoutAndar1] = useState([]);
  const [layoutAndar2, setLayoutAndar2] = useState([]);
  const [editedPassenger, setEditedPassenger] = useState({});
  const [editing, setEditing] = useState(false);
  const [duplicateWarnings, setDuplicateWarnings] = useState(
    new Array(reservations.length).fill(false)
  );
  const [underageWarnings, setUnderageWarnings] = useState(
    new Array(reservations.length).fill(false)
  );
  const [paymentRecords, setPaymentRecords] = useState([]);
  const [availableSeatsAndar1, setAvailableSeatsAndar1] = useState([]);
  const [availableSeatsAndar2, setAvailableSeatsAndar2] = useState([]);
  const [reservedSeats, setReservedSeats] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchPassengers = useCallback(async () => {
    const fetchedPassengers = await getAllPassengers();
    fetchedPassengers.sort((a, b) => a.nome.localeCompare(b.nome));
    setPassengers(fetchedPassengers);
  }, []);

  const fetchExistingReservations = useCallback(async () => {
    try {
      const fetchedReservations = await getReservationsByTravelId(travelId);
      setExistingReservations(fetchedReservations);
    } catch (error) {
      console.error('Erro ao buscar reservas existentes:', error);
    }
  }, [travelId]);

  const fetchOrderDetails = useCallback(async () => {
    if (!orderId || isCreating) return;
    try {
      const order = await getOrderById(travelId, orderId);
      if (order) {
        const sortedReservations = sortReservationsBySeatNumber(
          order.reservations.map((reservation) => ({
            ...reservation,
            numeroAssento: reservation.numeroAssento ?? null,
          }))
        );
        setReservations(sortedReservations);
        setPaymentDetails(order.detalhesPagamento);
        setPaymentRecords(order.detalhesPagamento.pagamentos || []);
      }
    } catch (error) {
      console.error('Erro ao buscar detalhes do pedido:', error);
    }
  }, [orderId, travelId, isCreating]);

  const fetchLayoutByVehicle = useCallback(async (vehicleId) => {
    try {
      const vehicleData = await getVehicleById(vehicleId);
      if (vehicleData?.layoutId) {
        const layoutData = await getLayoutById(vehicleData.layoutId);
        setLayoutAndar1(layoutData.firstFloor || []);
        setLayoutAndar2(layoutData.secondFloor || []);
      } else {
        throw new Error('Nenhum layoutId associado a este veículo.');
      }
    } catch (err) {
      console.error('Erro ao buscar layout do veículo:', err);
    }
  }, []);

  const fetchSeatData = useCallback(async () => {
    try {
      const travelData = await getTravelById(travelId);
      if (travelData?.veiculoId) await fetchLayoutByVehicle(travelData.veiculoId);

      const allReservedSeats = await getReservedSeats(travelId);
      setReservedSeats(allReservedSeats.filter((reservation) => reservation.status !== 'Cancelada'));
    } catch (error) {
      console.error('Erro ao buscar dados de assentos:', error);
    }
  }, [travelId, fetchLayoutByVehicle]);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoadingData(true);
        const promises = [fetchPassengers(), fetchSeatData(), fetchExistingReservations()];

        if (!isCreating) {
          promises.push(fetchOrderDetails());
        }

        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
      } finally {
        setLoadingData(false);
      }
    };
    fetchAllData();
  }, [fetchPassengers, fetchSeatData, fetchExistingReservations, fetchOrderDetails, isCreating]);

  const handleLapPassengerChange = (index) => {
    setReservations((prevReservations) => {
      const newReservations = [...prevReservations];
      newReservations[index] = {
        ...newReservations[index],
        lapPassenger: !newReservations[index].lapPassenger
      };
      return newReservations;
    });
  };

  const handleInputChange = (index, event, newValue) => {
    const passengerId = newValue ? newValue.id : null;
    const isDuplicate = checkDuplicatePassengerInTrip(passengerId, reservations[index]?.id);
    const isUnderagePassenger = newValue && newValue.menorDeIdade;

    setDuplicateWarnings((prevWarnings) => {
      const newWarnings = [...prevWarnings];
      newWarnings[index] = isDuplicate;
      return newWarnings;
    });

    setUnderageWarnings((prevWarnings) => {
      const newWarnings = [...prevWarnings];
      newWarnings[index] = isUnderagePassenger;
      return newWarnings;
    });

    setReservations((prevReservations) => {
      const newReservations = [...prevReservations];
      newReservations[index].passengerId = passengerId;
      return sortReservationsBySeatNumber(newReservations);
    });
    validateField(index, 'passengerId', passengerId);
  };

  const validateField = (index, name, value) => {
    let error = '';

    if (name === 'passengerId' && !value) {
      error = 'Passageiro é obrigatório.';
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [`${index}-${name}`]: error
    }));
  };

  const validatePaymentField = (name, value) => {
    let error = '';

    if (name === 'nomePagador' && (!value.trim() || value.length > 255)) {
      error = 'Nome do pagador é obrigatório e deve ter no máximo 255 caracteres.';
    } else if (name === 'cpfPagador' && (!value.trim() || !validarCPF(unformatCPF(value)))) {
      error = 'CPF do pagador é obrigatório e deve ser válido.';
    } else if (name === 'metodoPagamento' && !value.trim()) {
      error = 'Método de pagamento é obrigatório.';
    } else if (name === 'valorTotal' && parseFloat(value) < 0) {
      error = 'Valor total não pode ser negativo.';
    } else if (name === 'informacoesAdicionais' && value.length > 255) {
      error = 'Informações adicionais de pagamento devem ter no máximo 255 caracteres.';
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error
    }));
  };

  const isFormValid = () => {
    const passengerDetailsValid = reservations.every(
      (reservation) =>
        (reservation.numeroAssento !== null || reservation.passengerId) &&
        Object.keys(errors).every((key) => !errors[key])
    );

    const detalhesPagamentoValid = detalhesPagamento &&
      detalhesPagamento.nomePagador &&
      (
        (detalhesPagamento.cpfPagador && validarCPF(unformatCPF(detalhesPagamento.cpfPagador))) ||
        (detalhesPagamento.passaportePagador && detalhesPagamento.passaportePagador.trim() !== '')
      ) &&
      detalhesPagamento.metodoPagamento &&
      detalhesPagamento.valorTotal &&
      Object.keys(errors).every((key) => !errors[key]);

    const paymentRecordsValid = !errors.paymentRecord;

    return passengerDetailsValid && detalhesPagamentoValid && paymentRecordsValid;
  };

  const checkDuplicatePassengerInTrip = (newPassengerId, reservationId = null) => {
    return existingReservations.some(
      (reservation) =>
        reservation.passengerId === newPassengerId &&
        reservation.id !== reservationId &&
        reservation.status !== 'Cancelada'
    );
  };

  const handleAddNoSeatReservation = () => {
    setReservations((prevReservations) =>
      sortReservationsBySeatNumber([
        ...prevReservations,
        {
          numeroAssento: null,
          passengerId: null,
          pagador: false,
        },
      ])
    );
  };

  const handleAddPaymentRecord = () => {
    const today = new Date().toISOString().split('T')[0];
    setPaymentRecords([
      ...paymentRecords,
      { data: today, valor: '', metodoPagamento: detalhesPagamento.metodoPagamento }
    ]);
  };

  const handlePaymentRecordChange = (index, field, value) => {
    const updatedRecords = [...paymentRecords];
    updatedRecords[index][field] = value;

    if (field === 'valor') {
      const totalPaid = updatedRecords.reduce(
        (total, record) => total + parseFloat(record.valor.replace(',', '.') || 0),
        0
      );

      setPaymentDetails((prevDetails) => ({
        ...prevDetails,
        valorPago: totalPaid.toFixed(2),
        valorRestante: (
          parseFloat(prevDetails.valorTotal.replace(',', '.')) - totalPaid
        ).toFixed(2)
      }));
    }

    setPaymentRecords(updatedRecords);
  };

  const handleRemovePaymentRecord = (index) => {
    const updatedRecords = paymentRecords.filter((_, i) => i !== index);
    const valorTotalPago = updatedRecords
      .reduce((total, record) => total + parseFloat(record.valor.replace(',', '.') || 0), 0)
      .toFixed(2);
    setPaymentRecords(updatedRecords);

    setPaymentDetails((prevDetails) => ({
      ...prevDetails,
      valorPago: valorTotalPago,
      valorRestante: (
        parseFloat(prevDetails.valorTotal.replace(',', '.')) - valorTotalPago
      ).toFixed(2)
    }));

    updatedRecords.forEach((record, idx) => validatePaymentField('valor', record.valor));
  };

  const handlePaymentDetailChange = (name, value) => {
    setPaymentDetails((prevDetails) => {
      const updatedDetails = {
        ...prevDetails,
        [name]: value != null ? String(value) : '',
      };

      if (name === 'valorTotal') {
        const valorTotalNumerico = typeof value === 'string' ?
          parseFloat(value.replace(',', '.')) || 0 :
          parseFloat(value) || 0;

        const totalPago = paymentRecords.reduce((total, record) => {
          const valorPagamento = parseFloat(record.valor.replace(',', '.')) || 0;
          return total + valorPagamento;
        }, 0);

        const diferenca = Math.max(0, valorTotalNumerico - totalPago);

        // Ensure values are saved with two decimal places
        updatedDetails.valorTotal = valorTotalNumerico.toFixed(2);
        updatedDetails.valorPago = totalPago.toFixed(2);
        updatedDetails.valorRestante = diferenca.toFixed(2);
      }

      return updatedDetails;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isFormValid()) {
      setSnackbarMessage('Preencha todos os campos obrigatórios corretamente.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    try {
      const cleanDetalhesPagamento = {
        ...detalhesPagamento,
        passaportePagador: detalhesPagamento.passaportePagador || '',
        cpfPagador: detalhesPagamento.cpfPagador || '',
        rgPagador: detalhesPagamento.rgPagador || '',
        pagamentos: paymentRecords,
      };

      // Modo de criação
      if (isCreating) {
        // Criar novo pedido
        const orderData = {
          travelId,
          status: 'Pendente',
          detalhesPagamento: {
            ...cleanDetalhesPagamento,
            criadoEm: new Date().toISOString().split('T')[0],
          }
        };

        const newOrderId = await addOrder(orderData);

        const reservationPromises = reservations.map(reservation => {
          const reservationData = {
            ...reservation,
            travelId,
            orderId: newOrderId,
            status: 'Pendente',
            numeroAssento: reservation.numeroAssento ?? null,
            criadoEm: new Date().toISOString().split('T')[0],
          };
          return addReservation(newOrderId, reservationData);
        });

        await Promise.all(reservationPromises);
      }
      // Modo de edição
      else {
        if (!orderId) {
          throw new Error('ID do pedido não encontrado para atualização');
        }

        // Atualizar pedido existente
        await updateOrder(orderId, {
          travelId,
          detalhesPagamento: {
            ...cleanDetalhesPagamento,
            editadoEm: new Date().toISOString().split('T')[0],
          }
        });

        // Atualizar reservas
        const reservationPromises = reservations.map(reservation => {
          const reservationData = {
            ...reservation,
            travelId,
            orderId: orderId,
            numeroAssento: reservation.numeroAssento ?? null,
            editadoEm: new Date().toISOString().split('T')[0],
          };

          if (reservation.id) {
            return updateReservation(reservation.id, reservationData);
          } else {
            return addReservation(orderId, {
              ...reservationData,
              status: 'Pendente',
              criadoEm: new Date().toISOString().split('T')[0],
            });
          }
        });

        await Promise.all(reservationPromises);
      }

      setSnackbarMessage('Reservas salvas com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);

      if (previousPage) {
        navigate(previousPage);
      } else {
        navigate(-1);
      }
    } catch (err) {
      console.error('Erro ao salvar reservas:', err);
      setSnackbarMessage(`Erro ao salvar reservas: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenFormDialog = () => {
    setOpenFormDialog(true);
    setEditedPassenger({});
    setEditing(false);
  };

  const handleCloseFormDialog = () => {
    setOpenFormDialog(false);
  };

  const handleOpenSeatSelection = (index) => {
    setSelectedReservationIndex(index);
    setOpenSeatSelectionDialog(true);
  };

  const handleCloseSeatSelection = () => {
    setOpenSeatSelectionDialog(false);
  };

  const currentlyAllocatedSeats = useMemo(
    () => reservations.map(reservation => reservation.numeroAssento),
    [reservations]
  );

  const handleSeatChange = (seatNumber) => {
    setReservations((prevReservations) => {
      const newReservations = [...prevReservations];
      if (selectedReservationIndex !== null) {
        newReservations[selectedReservationIndex].numeroAssento = seatNumber;
      }
      return sortReservationsBySeatNumber(newReservations);
    });
    handleCloseSeatSelection();
  };

  const handleRemoveReservation = async (index) => {
    const reservationToRemove = reservations[index];

    if (!reservationToRemove) {
      console.error('Nenhuma reserva encontrada para o índice:', index);
      return;
    }

    // Verifica se está no modo de edição
    if (isEditing && reservationToRemove.id) {
      try {

        if (!travelId || !reservationToRemove.id) {
          throw new Error('Parâmetros inválidos para cancelar a reserva');
        }

        await cancelReservation(travelId, editingOrderId || orderId, reservationToRemove.id);

        setSnackbarMessage('Reserva cancelada com sucesso.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);

        setReservations((prevReservations) => {
          const updatedReservations = [...prevReservations];
          updatedReservations[index].status = 'Cancelada';
          return updatedReservations;
        });
      } catch (error) {
        console.error('Erro ao cancelar reserva:', error);
        setSnackbarMessage('Erro ao cancelar a reserva. Tente novamente.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } else {
      setReservations((prevReservations) => prevReservations.filter((_, idx) => idx !== index));
    }
  };

  const handlePayerChange = (index) => {
    setReservations((prevReservations) => {
      // Atualiza a lista de reservas, definindo apenas um pagador
      const newReservations = prevReservations.map((reservation, idx) => ({
        ...reservation,
        pagador: idx === index ? !reservation.pagador : false, // Define pagador apenas no índice selecionado
      }));

      // Identifica o passageiro vinculado à reserva que foi marcada como pagador
      const selectedReservation = newReservations.find((res) => res.pagador);
      if (selectedReservation) {
        const payerPassenger = passengers.find((passenger) => passenger.id === selectedReservation.passengerId);

        if (payerPassenger) {
          setPaymentDetails((prevDetails) => ({
            ...prevDetails,
            nomePagador: payerPassenger.nome,
            passaportePagador: payerPassenger.estrangeiro ? payerPassenger.passaporte || '' : '',
            cpfPagador: payerPassenger.estrangeiro ? '' : payerPassenger.cpf || '',
            rgPagador: payerPassenger.estrangeiro ? '' : payerPassenger.rg || '',
          }));

          setPagadorEstrangeiro(!!payerPassenger.estrangeiro);
        }
      } else {
        setPaymentDetails({
          ...initialReservation,
          metodoPagamento: detalhesPagamento.metodoPagamento,
        });
        setPagadorEstrangeiro(false);
      }

      return newReservations;
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmitWithReceipt = async (event) => {
    event.preventDefault();

    if (!isFormValid()) {
      setSnackbarMessage('Preencha todos os campos obrigatórios corretamente.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    try {
      const cleanDetalhesPagamento = {
        ...detalhesPagamento,
        passaportePagador: detalhesPagamento.passaportePagador || '',
        cpfPagador: detalhesPagamento.cpfPagador || '',
        rgPagador: detalhesPagamento.rgPagador || '',
        pagamentos: paymentRecords,
      };

      let savedOrderId;

      // Modo de criação
      if (isCreating) {
        const orderData = {
          travelId,
          status: 'Pendente',
          detalhesPagamento: {
            ...cleanDetalhesPagamento,
            criadoEm: new Date().toISOString().split('T')[0],
          }
        };

        savedOrderId = await addOrder(orderData);

        const reservationPromises = reservations.map(reservation => {
          const reservationData = {
            ...reservation,
            travelId,
            orderId: savedOrderId,
            status: 'Pendente',
            numeroAssento: reservation.numeroAssento ?? null,
            criadoEm: new Date().toISOString().split('T')[0],
          };
          return addReservation(savedOrderId, reservationData);
        });

        await Promise.all(reservationPromises);
      } else {
        savedOrderId = orderId;

        await updateOrder(orderId, {
          travelId,
          detalhesPagamento: {
            ...cleanDetalhesPagamento,
            editadoEm: new Date().toISOString().split('T')[0],
          }
        });

        const reservationPromises = reservations.map(reservation => {
          const reservationData = {
            ...reservation,
            travelId,
            orderId: orderId,
            numeroAssento: reservation.numeroAssento ?? null,
            editadoEm: new Date().toISOString().split('T')[0],
          };

          if (reservation.id) {
            return updateReservation(reservation.id, reservationData);
          } else {
            return addReservation(orderId, {
              ...reservationData,
              status: 'Pendente',
              criadoEm: new Date().toISOString().split('T')[0],
            });
          }
        });

        await Promise.all(reservationPromises);
      }

      // Generate receipt after successful save
      const travel = await getTravelById(travelId);
      const orderForReceipt = {
        id: savedOrderId,
        detalhesPagamento: cleanDetalhesPagamento,
        reservations: reservations
      };

      await generateReceiptPDF(orderForReceipt, passengers, travel);

      setSnackbarMessage('Reservas salvas e recibo gerado com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);

      if (previousPage) {
        navigate(previousPage);
      } else {
        navigate(-1);
      }
    } catch (err) {
      console.error('Erro ao salvar reservas ou gerar recibo:', err);
      setSnackbarMessage(`Erro ao processar: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Box>
      <Box sx={{ p: theme.spacing(3) }}>
        {loadingData ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              height: 'auto',
              marginTop: theme.spacing(3),
            }}
          >
            <TravelLoading />
          </Box>
        ) : (
          <>
            <Fade in={true}>
              <IconButton onClick={() => navigate(previousPage || -1)} sx={{ mb: theme.spacing(2) }}>
                <ArrowBackIcon />
              </IconButton>
            </Fade>
            <Card
              variant="outlined"
              sx={{
                mb: theme.spacing(3),
                borderRadius: 2, // Sem bordas arredondadas para o card
                boxShadow: theme.shadows[1],
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Alocar Passageiros
                  <Box component="span" sx={{ ml: 1 }}> {/* Adiciona margem à esquerda */}
                    <PassengerAllocationHelp />
                  </Box>
                </Typography>

                <form onSubmit={handleSubmit} noValidate>
                  <Box sx={{ mb: theme.spacing(3) }}>
                    <PassengerSelection
                      reservations={reservations}
                      passengers={passengers}
                      duplicateWarnings={duplicateWarnings}
                      underageWarnings={underageWarnings}
                      loadingPassengers={loadingPassengers}
                      handleInputChange={handleInputChange}
                      handlePayerChange={handlePayerChange}
                      handleOpenFormDialog={handleOpenFormDialog}
                      handleOpenSeatSelection={handleOpenSeatSelection}
                      handleRemoveReservation={handleRemoveReservation}
                      handleAddNoSeatReservation={handleAddNoSeatReservation}
                      editingReservation={isEditing}
                      handleLapPassengerChange={handleLapPassengerChange}
                    />
                  </Box>
                  <Divider sx={{ my: theme.spacing(3) }} />
                  <Box sx={{ mb: theme.spacing(3) }}>
                    <PaymentDetailsForm
                      detalhesPagamento={detalhesPagamento}
                      handlePaymentDetailChange={handlePaymentDetailChange}
                      errors={errors}
                      validatePaymentField={validatePaymentField}
                      setPagadorEstrangeiro={setPagadorEstrangeiro}
                    />
                  </Box>
                  <Divider sx={{ my: theme.spacing(3) }} />
                  <Box sx={{ mb: theme.spacing(3) }}>
                    <PaymentRecords
                      paymentRecords={paymentRecords}
                      handlePaymentRecordChange={handlePaymentRecordChange}
                      handleRemovePaymentRecord={handleRemovePaymentRecord}
                      handleAddPaymentRecord={handleAddPaymentRecord}
                      detalhesPagamento={detalhesPagamento}
                      setErrors={setErrors}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: theme.spacing(2) }}>
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: 2,
                        color: theme.palette.action,
                      }}
                      onClick={() => navigate(previousPage || -1)}
                    >
                      {editingReservation ? 'Descartar Alterações' : 'Voltar'}
                    </Button>

                    <Box sx={{ display: 'flex', gap: 2 }}> {/* Added gap between buttons */}
                      <Button
                        onClick={handleSubmitWithReceipt}
                        variant="outlined" // Kept contained for primary action
                        disabled={loading}
                        startIcon={loading && <CircularProgress size={20} />}
                        sx={{ borderRadius: 2 }}
                      >
                        {editingReservation ? 'Atualizar e Gerar Recibo' : 'Salvar e Gerar Recibo'}
                      </Button>

                      <Button
                        onClick={handleSubmit}
                        variant="contained" // Changed to outlined for better visual hierarchy
                        disabled={loading}
                        startIcon={loading && <CircularProgress size={20} />}
                        sx={{ borderRadius: 2 }}
                      >
                        {editingReservation ? 'Atualizar' : 'Salvar'}
                      </Button>
                    </Box>
                  </Box>
                </form>
                {errors.form && (
                  <Typography color="error" sx={{ mt: theme.spacing(2) }}>
                    {errors.form}
                  </Typography>
                )}
              </CardContent>
            </Card>
            <Dialog open={openFormDialog} onClose={handleCloseFormDialog}>
              <DialogContent>
                <PassengerForm
                  editedPassenger={editedPassenger}
                  setEditedPassenger={setEditedPassenger}
                  errors={errors}
                  setErrors={setErrors}
                  handleCloseFormDialog={handleCloseFormDialog}
                  fetchPassengers={fetchPassengers}
                  editing={editing}
                  passageiros={passengers}
                  setOpenSnackbar={setSnackbarOpen}
                  setSnackbarMessage={setSnackbarMessage}
                />
              </DialogContent>
            </Dialog>
            <SeatChangeDialog
              open={openSeatSelectionDialog}
              onClose={handleCloseSeatSelection}
              currentSeat={selectedReservationIndex !== null ? reservations[selectedReservationIndex].numeroAssento : null}
              layoutAndar1={layoutAndar1}
              layoutAndar2={layoutAndar2}
              reservedSeats={reservedSeats}
              allocatedSeats={currentlyAllocatedSeats}
              onSeatChange={handleSeatChange}
            />
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
              <Alert
                onClose={() => setSnackbarOpen(false)}
                severity={snackbarSeverity}
                sx={{ width: '100%', backgroundColor: theme.palette.background.default }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </>
        )}
      </Box>
    </Box >
  );
};

export default PassengerAllocationPage;
