import { formatCPF, formatTelefone, formatDate, formatDateForFilename } from './utils';
import jsPDF from 'jspdf';
import logoTravell from '../../assets/logo-travell-combr.png';
import 'jspdf-autotable';
import { auth, db } from '../../firebaseConfig';
import { getDoc, doc as firestoreDoc } from 'firebase/firestore';
import { getAgencyLogo } from '../../pages/UpdateAgency/services/LogoService';
import { convertSvgToPng } from './convertSvgToPng';

export const exportToPDF = async (travel, reservations, passengers, selectedFields, sortOrder) => {
  const doc = new jsPDF('landscape', 'pt');

  // Get agency information
  const user = auth.currentUser;
  const userRef = firestoreDoc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  const agencyRef = firestoreDoc(db, 'agencies', userDoc.data().agencyId);
  const agencyDoc = await getDoc(agencyRef);
  const agencyData = agencyDoc.data();

  // Get agency logo
  let logoData = null;
  try {
    logoData = await getAgencyLogo(userDoc.data().agencyId);
  } catch (error) {
    console.warn('Unable to fetch agency logo:', error);
    // Continue without logo
  }

  const getImageFormat = (base64String, contentType) => {
    if (contentType) {
      if (contentType.includes('jpeg') || contentType.includes('jpg')) return 'JPEG';
      if (contentType.includes('png')) return 'PNG';
      if (contentType.includes('svg')) return 'SVG';
    }

    // Fallback to checking base64 header if content type is not reliable
    if (base64String.startsWith('data:image/jpeg') || base64String.startsWith('data:image/jpg')) return 'JPEG';
    if (base64String.startsWith('data:image/png')) return 'PNG';
    if (base64String.startsWith('data:image/svg')) return 'SVG';

    return 'PNG';
  };

  const truncateText = (text, maxLength) => {
    if (!text) return 'Não informado';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  const columns = [
    { title: "Assento", dataKey: "numeroAssento" },
    { title: "Nome do Passageiro", dataKey: "passengerNome" },
    { title: "Documentos", dataKey: "passengerDocumentos" },
    { title: "Data Nascimento", dataKey: "dataNascimento" },
    { title: "Idade", dataKey: "passengerIdade" },
    { title: "Endereço", dataKey: "passengerEndereco" },
    { title: "Telefone", dataKey: "passengerTelefone" },
    { title: "Informações Adicionais", dataKey: "informacoesAdicionais" }
  ];

  const filteredColumns = columns.filter(col => selectedFields[col.dataKey]);

  const calculateAge = (birthDate) => {
    if (!birthDate) return 'Não informado';
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age.toString();
  };

  const sortedReservations = reservations
    .filter(reservation => reservation.status !== 'Cancelada')
    .sort((a, b) => {
      if (sortOrder === "numeroAssento") {
        return (a.numeroAssento || 0) - (b.numeroAssento || 0);
      }
      if (sortOrder === "passengerNome") {
        const passengerA = passengers.find(p => p.id === a.passengerId)?.nome || "";
        const passengerB = passengers.find(p => p.id === b.passengerId)?.nome || "";
        return passengerA.localeCompare(passengerB);
      }
      return 0;
    });

  const rows = sortedReservations.map(reservation => {
    const passenger = passengers.find(p => p.id === reservation.passengerId) || {};
    const documentos = passenger.estrangeiro ?
      `Passaporte: ${passenger.passaporte || 'Não informado'}` :
      `CPF: ${passenger.cpf ? formatCPF(passenger.cpf) : 'Não informado'} / RG: ${passenger.rg || 'Não informado'}`;
    const menorDeIdade = passenger.menorDeIdade ? 'Menor de Idade' : '';

    return {
      numeroAssento: reservation.lapPassenger
        ? 'Ocupante de Colo'
        : (reservation.numeroAssento || 'Sem assento'),
      passengerNome: passenger.nome || 'Não informado',
      passengerDocumentos: documentos.trim(),
      dataNascimento: formatDate(passenger.dataNascimento),
      passengerIdade: calculateAge(passenger.dataNascimento),
      passengerEndereco: truncateText(passenger.endereco, 200),
      passengerTelefone: formatTelefone(passenger.telefone),
      informacoesAdicionais: truncateText(`${menorDeIdade} ${reservation.detalhesPagamento?.informacoesAdicionais || ''}`, 50)
    };
  });

  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;
  let startY = 70;

  // Load both logos
  const travellImg = new Image();
  travellImg.src = logoTravell;

  // Wait for Travell logo to load
  await new Promise((resolve, reject) => {
    travellImg.onload = resolve;
    travellImg.onerror = reject;
  });

  // Handle agency logo display
  if (logoData && logoData.base64) {
    try {
      let imageData = logoData.base64;
      let imageFormat = getImageFormat(imageData, logoData.contentType);

      // Convert SVG to PNG if needed
      if (imageFormat === 'SVG') {
        imageData = await convertSvgToPng(logoData.base64);
        imageFormat = 'PNG';
      }

      // Create image element for size calculation
      const img = new Image();
      img.src = imageData;

      // Wait for image to load
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = (error) => {
          console.error('Error loading image:', error);
          reject(error);
        };
      });

      // Calculate logo dimensions (max height 60px for landscape)
      const maxHeight = 60;
      const aspectRatio = img.width / img.height;
      const logoHeight = Math.min(maxHeight, img.height);
      const logoWidth = logoHeight * aspectRatio;

      // Position agency logo at top right
      const logoX = pageWidth - logoWidth - 40;
      const logoY = 15;

      // Add agency logo to PDF
      doc.addImage(
        imageData,
        imageFormat,
        logoX,
        logoY,
        logoWidth,
        logoHeight
      );

      // Add agency name and phone
      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      const agencyPhone = agencyData.phone ? formatTelefone(agencyData.phone) : '';
      const agencyText = agencyData.name + (agencyPhone ? ` | ${agencyPhone}` : '');
      doc.text(agencyText, 20, 20);

    } catch (error) {
      console.error('Error adding agency logo to PDF:', error);
    }
  } else {
    // If no logo, still display agency name and phone
    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    const agencyPhone = agencyData.phone ? formatTelefone(agencyData.phone) : '';
    const agencyText = agencyData.name + (agencyPhone ? ` | ${agencyPhone}` : '');
    doc.text(agencyText, 20, 20);
  }

  // Add Travell logo (smaller and at the bottom)
  const travellLogoWidth = 90;
  const travellLogoHeight = 25;
  const travellLogoX = pageWidth - travellLogoWidth - 20;
  const travellLogoY = pageHeight - travellLogoHeight - 10;

  doc.addImage(
    travellImg,
    'PNG',
    travellLogoX,
    travellLogoY,
    travellLogoWidth,
    travellLogoHeight
  );

  // Add clickable link for Travell logo
  doc.link(travellLogoX, travellLogoY, travellLogoWidth, travellLogoHeight, { url: 'https://travell.com.br' });

  // Add travel details
  doc.setFontSize(14);
  doc.setFont('helvetica', 'bold');
  doc.text('Detalhes da Viagem', 20, 35);

  doc.setFontSize(10);
  doc.setFont('helvetica', 'normal');

  const infoLine1 = truncateText(
    `Identificador: ${travel?.identificador || 'Não informado'} | Origem: ${travel?.origem || 'Não informado'} | Destino: ${travel?.destino || 'Não informado'}`,
    120
  );
  doc.text(infoLine1, 20, 50);

  const infoLine2 = truncateText(
    `Ida: ${formatDate(travel?.dataIda) || 'Não informado'} às ${travel?.horarioIda || 'Não informado'} | ${travel?.somenteIda
      ? 'Somente Ida'
      : `Volta: ${formatDate(travel?.dataRetorno) || 'Não informado'} às ${travel?.horarioRetorno || 'Não informado'}`
    }`,
    120
  );
  doc.text(infoLine2, 20, 65);

  if (travel?.veiculo) {
    const infoLine3 = truncateText(
      `Veículo: ${travel.veiculo.identificadorVeiculo || 'Não informado'} - ${travel.veiculo.placa || 'Não informado'} (${travel.veiculo.empresa || 'Não informado'
      })${travel?.informacoesAdicionais ? ` | Info: ${truncateText(travel.informacoesAdicionais, 50)}` : ''}`,
      120
    );
    doc.text(infoLine3, 20, 80);
    startY = 85;
  }

  // Generate table
  doc.autoTable({
    head: [filteredColumns.map(col => col.title)],
    body: rows.map(row => filteredColumns.map(col => row[col.dataKey])),
    startY: startY,
    theme: 'striped',
    margin: { top: 10, bottom: 30, left: 20, right: 20 },
    styles: { fontSize: 10, halign: 'center' },
    headStyles: { fillColor: [25, 118, 210], textColor: [255, 255, 255] },
    alternateRowStyles: { fillColor: [240, 240, 240] },
    didDrawPage: function (data) {
      // Add page numbers
      doc.setFontSize(8);
      doc.setTextColor(40);
      let str = 'Página ' + doc.internal.getNumberOfPages();
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + doc.internal.getNumberOfPages();
      }
      doc.text(str, data.settings.margin.left, pageHeight - 10);

      // Re-add Travell logo on each page
      doc.addImage(
        travellImg,
        'PNG',
        travellLogoX,
        travellLogoY,
        travellLogoWidth,
        travellLogoHeight
      );
      doc.link(travellLogoX, travellLogoY, travellLogoWidth, travellLogoHeight, { url: 'https://travell.com.br' });
    },
  });

  // Define filename and save
  const identifier = travel?.identificador || 'sem-identificador';
  const origem = travel?.origem || 'sem-origem';
  const destino = travel?.destino || 'sem-destino';
  const dataIda = formatDateForFilename(travel?.dataIda || 'sem-data-ida');
  const dataRetorno = travel?.somenteIda ? 'somente-ida' : formatDateForFilename(travel?.dataRetorno || 'sem-data-retorno');
  const filename = `viagem_${identifier}_de_${origem}_na_data_${dataIda}_a_${destino}_com_volta_${dataRetorno}.pdf`;

  doc.save(filename);
};