import { db, auth } from '../../../firebaseConfig';
import { collection, doc, addDoc, getDocs, updateDoc, getDoc, query, where, orderBy, deleteDoc } from 'firebase/firestore';

const getAgencyRef = async () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('Usuário não autenticado');
  }

  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);

  if (!userDoc.exists()) {
    throw new Error('Documento do usuário não encontrado');
  }

  const userData = userDoc.data();
  if (!userData?.agencyId) {
    throw new Error('ID da agência não encontrado para este usuário');
  }

  return doc(db, 'agencies', userData.agencyId);
};

export const getPaginatedHotelReservations = async (pageSize = 12, searchTerm = '', sortOrder = 'desc', currentPage = 1) => {
  try {
    const agencyRef = await getAgencyRef();
    const reservationsRef = collection(agencyRef, 'hotelReservations');

    let queryConstraints = [
      where('estaAtivo', '==', true),
      orderBy('metadata.createdAt', sortOrder)
    ];

    const fullQuery = query(reservationsRef, ...queryConstraints);
    const fullSnapshot = await getDocs(fullQuery);
    let allReservations = fullSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();
      allReservations = allReservations.filter(reservation =>
        reservation.hotelName?.toLowerCase().includes(searchTermLower) ||
        reservation.hotelId?.toLowerCase().includes(searchTermLower) ||
        reservation.payerName?.toLowerCase().includes(searchTermLower) ||
        reservation.payerDocument?.includes(searchTerm)
      );
    }

    const total = allReservations.length;
    const startIndex = (currentPage - 1) * pageSize;
    const paginatedReservations = allReservations.slice(startIndex, startIndex + pageSize);

    return {
      reservations: paginatedReservations,
      total,
      hasMore: total > startIndex + pageSize
    };
  } catch (error) {
    console.error('Erro ao buscar reservas:', error);
    throw new Error('Falha ao buscar reservas');
  }
};

export const getHotelReservations = async () => {
  try {
    if (!auth.currentUser) {
      throw new Error('Usuário não autenticado');
    }

    const agencyRef = await getAgencyRef();
    const hotelReservationsRef = collection(agencyRef, 'hotelReservations');

    const q = query(
      hotelReservationsRef,
      where('estaAtivo', '==', true),
      orderBy('metadata.createdAt', 'desc')
    );

    const snapshot = await getDocs(q);
    const reservations = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    return reservations.filter(reservation =>
      reservation.hotelName &&
      reservation.checkInDate &&
      reservation.checkOutDate
    );
  } catch (error) {
    console.error('Erro detalhado ao buscar reservas:', error);
    if (error.message === 'Usuário não autenticado') {
      throw new Error('Sessão expirada. Por favor, faça login novamente.');
    }
    throw new Error('Não foi possível carregar as reservas. ' + error.message);
  }
};

export const addHotelReservation = async (reservationData) => {
  try {
    if (!auth.currentUser) {
      throw new Error('Usuário não autenticado');
    }

    const agencyRef = await getAgencyRef();
    const hotelReservationsRef = collection(agencyRef, 'hotelReservations');

    const timestamp = new Date().toISOString();
    const reservationWithMetadata = {
      ...reservationData,
      status: 'Confirmada',
      estaAtivo: true,
      metadata: {
        createdAt: timestamp,
        updatedAt: timestamp,
        deletedAt: null,
        createdBy: auth.currentUser.uid
      }
    };

    const docRef = await addDoc(hotelReservationsRef, reservationWithMetadata);
    return { id: docRef.id, ...reservationWithMetadata };
  } catch (error) {
    console.error('Erro ao adicionar reserva:', error);
    throw new Error('Não foi possível adicionar a reserva. ' + error.message);
  }
};

export const updateHotelReservation = async (reservationId, reservationData) => {
  try {
    if (!auth.currentUser) {
      throw new Error('Usuário não autenticado');
    }

    const agencyRef = await getAgencyRef();
    const reservationRef = doc(agencyRef, 'hotelReservations', reservationId);

    const currentDoc = await getDoc(reservationRef);
    if (!currentDoc.exists()) {
      throw new Error('Reserva não encontrada');
    }

    const currentData = currentDoc.data();
    const timestamp = new Date().toISOString();

    const updatedData = {
      ...currentData,         
      ...reservationData,     
      estaAtivo: true,       
      status: currentData.status || 'Confirmada', 
      metadata: {
        ...currentData.metadata,  
        updatedAt: timestamp,     
        updatedBy: auth.currentUser.uid
      }
    };

    await updateDoc(reservationRef, updatedData);
    return { id: reservationId, ...updatedData };
  } catch (error) {
    console.error('Erro ao atualizar reserva:', error);
    throw new Error('Não foi possível atualizar a reserva. ' + error.message);
  }
};

export const deleteHotelReservation = async (reservationId) => {
  try {
    if (!auth.currentUser) {
      throw new Error('Usuário não autenticado');
    }

    const agencyRef = await getAgencyRef();
    const reservationRef = doc(agencyRef, 'hotelReservations', reservationId);

    await deleteDoc(reservationRef);
    return true;

  } catch (error) {
    console.error('Erro ao deletar reserva:', error);
    throw new Error('Não foi possível deletar a reserva. ' + error.message);
  }
};