import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatCPF, formatDate, normalizeValue } from '../../../shared/utils/utils';
import { convertSvgToPng } from '../../../shared/utils/convertSvgToPng';
import { auth, db } from '../../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { getAgencyLogo } from '../../../pages/UpdateAgency/services/LogoService';

export const generateHotelReceiptPDF = async (reservation) => {
    // Get agency information
    const user = auth.currentUser;
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    const agencyRef = doc(db, 'agencies', userDoc.data().agencyId);
    const agencyDoc = await getDoc(agencyRef);
    const agencyData = agencyDoc.data();

    // Get agency logo
    let logoData = null;
    try {
        logoData = await getAgencyLogo(userDoc.data().agencyId);
    } catch (error) {
        console.warn('Unable to fetch agency logo:', error);
    }

    const getImageFormat = (base64String, contentType) => {
        if (contentType) {
            if (contentType.includes('jpeg') || contentType.includes('jpg')) return 'JPEG';
            if (contentType.includes('png')) return 'PNG';
            if (contentType.includes('svg')) return 'SVG';
        }
        if (base64String.startsWith('data:image/jpeg') || base64String.startsWith('data:image/jpg')) return 'JPEG';
        if (base64String.startsWith('data:image/png')) return 'PNG';
        if (base64String.startsWith('data:image/svg')) return 'SVG';
        return 'PNG';
    };

    const pdfDoc = new jsPDF('portrait', 'pt');
    const pageHeight = pdfDoc.internal.pageSize.height;
    const pageWidth = pdfDoc.internal.pageSize.width;
    const leftMargin = 30;
    const rightMargin = pageWidth - 30;
    const contentWidth = rightMargin - leftMargin;

    // Function to draw page border
    const drawPageBorder = () => {
        pdfDoc.setDrawColor(200);
        pdfDoc.setLineWidth(0.5);
        pdfDoc.rect(leftMargin, 30, contentWidth, pageHeight - 60);
    };

    // Function to handle page breaks
    const addPageIfNeeded = (currentY) => {
        if (currentY >= pageHeight - 60) {
            pdfDoc.addPage();
            drawPageBorder();
            return 60;
        }
        return currentY;
    };

    // Draw border on first page
    drawPageBorder();

    // Title
    pdfDoc.setFontSize(10);
    pdfDoc.setFont('helvetica', 'bold');
    pdfDoc.text('Recibo de Reserva de Hotel', pageWidth / 2, 20, { align: 'center' });

    // Format current date and time
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('pt-BR');
    const formattedTime = currentDate.toLocaleTimeString('pt-BR');

    // Handle logo and header
    let yPos = 45;
    const headerColumnWidth = contentWidth / 3;

    // Add logo if available
    if (logoData && logoData.base64) {
        try {
            let imageData = logoData.base64;
            let imageFormat = getImageFormat(imageData, logoData.contentType);

            if (imageFormat === 'SVG') {
                imageData = await convertSvgToPng(logoData.base64);
                imageFormat = 'PNG';
            }

            const img = new Image();
            img.src = imageData;

            await new Promise((resolve, reject) => {
                img.onload = resolve;
                img.onerror = reject;
            });

            const maxHeight = 80;
            const aspectRatio = img.width / img.height;
            const logoHeight = Math.min(maxHeight, img.height);
            const logoWidth = logoHeight * aspectRatio;
            const centerX = leftMargin + (contentWidth - logoWidth) / 2;

            pdfDoc.addImage(imageData, imageFormat, centerX, yPos, logoWidth, logoHeight);
            yPos += logoHeight + 20;
        } catch (error) {
            console.error('Error adding logo to PDF:', error);
        }
    }

    // Create a box for each data group
    const createDataBox = (label, value, x, y, width, height = 35) => {
        pdfDoc.setDrawColor(200);
        pdfDoc.setLineWidth(0.5);
        pdfDoc.rect(x, y, width, height);

        pdfDoc.setFontSize(8);
        pdfDoc.setFont('helvetica', 'bold');
        pdfDoc.text(label, x + 5, y + 12);

        pdfDoc.setFontSize(10);
        pdfDoc.setFont('helvetica', 'normal');
        pdfDoc.text(value || 'NÃO INFORMADO', x + 5, y + 27);
    };

    // Add agency info
    pdfDoc.setFontSize(12);
    pdfDoc.setFont('helvetica', 'bold');
    pdfDoc.text(agencyData.name, leftMargin + 10, yPos);
    pdfDoc.setFontSize(8);
    pdfDoc.setFont('helvetica', 'normal');
    pdfDoc.text(`Tel: ${agencyData.phone}`, leftMargin + 10, yPos + 12);
    pdfDoc.setFontSize(10);
    pdfDoc.setFont('helvetica', 'bold');
    if (reservation.hotelId) {
        pdfDoc.text(`ID: ${reservation.hotelId}`, leftMargin + 10, yPos + 24);
    }

    // Center Column - Date/Time
    pdfDoc.setFont('helvetica', 'bold');
    pdfDoc.text(`Data e Hora do Recibo:`, leftMargin + headerColumnWidth + 10, yPos);
    pdfDoc.setFont('helvetica', 'normal');
    pdfDoc.text(`${formattedDate} ${formattedTime}`,
        leftMargin + headerColumnWidth + 10, yPos + 15);

    // Right Column - Total Amount
    pdfDoc.setFont('helvetica', 'bold');
    pdfDoc.text('Valor Total:', leftMargin + (headerColumnWidth * 2) + 10, yPos);
    pdfDoc.setFont('helvetica', 'normal');
    pdfDoc.text(`R$ ${normalizeValue(reservation.totalAmount)}`,
        leftMargin + (headerColumnWidth * 2) + 10, yPos + 15);

    // Hotel Information Section
    yPos += 40;
    yPos = addPageIfNeeded(yPos);

    pdfDoc.setFontSize(11);
    pdfDoc.setFont('helvetica', 'bold');
    pdfDoc.text('Informações do Hotel', leftMargin + 10, yPos);

    yPos += 10;
    createDataBox('Nome do Hotel', reservation.hotelName, leftMargin + 10, yPos, contentWidth - 20);

    yPos += 45;
    const columnWidth = (contentWidth - 20) / 2;
    createDataBox('Tipo de Acomodação', reservation.roomType, leftMargin + 10, yPos, columnWidth - 5);
    createDataBox('Tipo de Pensão', reservation.pensionType, leftMargin + columnWidth + 15, yPos, columnWidth - 5);

    yPos += 45;
    createDataBox('Endereço do Hotel', reservation.hotelAddress, leftMargin + 10, yPos, contentWidth - 20);

    // Check-in/Check-out Information
    yPos += 45;
    createDataBox('Check-in', `${formatDate(reservation.checkInDate)} ${reservation.checkInTime}`,
        leftMargin + 10, yPos, columnWidth - 5);
    createDataBox('Check-out', `${formatDate(reservation.checkOutDate)} ${reservation.checkOutTime}`,
        leftMargin + columnWidth + 15, yPos, columnWidth - 5);

    // Payer Information
    yPos += 60;
    yPos = addPageIfNeeded(yPos);
    pdfDoc.setFontSize(11);
    pdfDoc.setFont('helvetica', 'bold');
    pdfDoc.text('Informações do Pagador', leftMargin + 10, yPos);

    yPos += 10;
    createDataBox('Nome do Pagador', reservation.payerName, leftMargin + 10, yPos, contentWidth - 20);

    yPos += 45;
    if (reservation.isForeignPayer) {
        createDataBox('Passaporte', reservation.payerPassport, leftMargin + 10, yPos, columnWidth - 5);
        createDataBox('Email', reservation.payerEmail || 'Não informado', leftMargin + columnWidth + 15, yPos, columnWidth - 5);
    } else {
        createDataBox('CPF', formatCPF(reservation.payerDocument), leftMargin + 10, yPos, columnWidth - 5);
        createDataBox('RG', reservation.payerRG, leftMargin + columnWidth + 15, yPos, columnWidth - 5);
        yPos += 45;
        createDataBox('Email', reservation.payerEmail || 'Não informado', leftMargin + 10, yPos, contentWidth - 20);
    }

    // Guests List
    yPos += 60;
    yPos = addPageIfNeeded(yPos);
    pdfDoc.setFontSize(11);
    pdfDoc.setFont('helvetica', 'bold');
    pdfDoc.text('Hóspedes', leftMargin + 10, yPos);

    yPos += 10;
    if (reservation.guests?.length > 0) {
        pdfDoc.autoTable({
            startY: yPos,
            head: [['Tipo', 'Nome', 'Data de Nascimento']],
            body: reservation.guests.map(guest => [
                guest.type,
                guest.name,
                formatDate(guest.birthDate)
            ]),
            styles: {
                fontSize: 9,
                cellPadding: 5
            },
            headStyles: {
                fillColor: [240, 240, 240],
                textColor: [0, 0, 0],
                fontSize: 9,
                fontStyle: 'bold'
            },
            margin: { left: leftMargin + 10, right: rightMargin - 10 },
            tableWidth: contentWidth - 20,
            didDrawPage: () => {
                drawPageBorder();
            }
        });

        yPos = pdfDoc.lastAutoTable.finalY + 20;
    }

    // Payment Information
    yPos = addPageIfNeeded(yPos);
    pdfDoc.setFontSize(11);
    pdfDoc.setFont('helvetica', 'bold');
    pdfDoc.text('Detalhes dos Pagamentos', leftMargin + 10, yPos);

    yPos += 10;
    if (reservation.payments?.length > 0) {
        pdfDoc.autoTable({
            startY: yPos,
            head: [['Data', 'Valor', 'Método de Pagamento']],
            body: reservation.payments.map(payment => [
                formatDate(payment.date),
                `R$ ${normalizeValue(payment.amount)}`,
                payment.paymentMethod
            ]),
            styles: {
                fontSize: 9,
                cellPadding: 5
            },
            headStyles: {
                fillColor: [240, 240, 240],
                textColor: [0, 0, 0],
                fontSize: 9,
                fontStyle: 'bold'
            },
            margin: { left: leftMargin + 10, right: rightMargin - 10 },
            tableWidth: contentWidth - 20,
            didDrawPage: () => {
                drawPageBorder();
            }
        });

        yPos = pdfDoc.lastAutoTable.finalY + 20;
    }

    yPos = addPageIfNeeded(yPos);

    // Summary boxes
    const totalPaid = reservation.payments.reduce((sum, payment) =>
        sum + (parseFloat(payment.amount) || 0), 0);
    const remaining = parseFloat(reservation.totalAmount) - totalPaid;

    const summaryY = pageHeight - 140;
    const summaryWidth = 160;
    createDataBox('Total Pago', `R$ ${normalizeValue(totalPaid.toFixed(2))}`,
        rightMargin - summaryWidth - 10, yPos, summaryWidth, 30);
    createDataBox('Valor Restante', `R$ ${normalizeValue(remaining.toFixed(2))}`,
        rightMargin - summaryWidth - 10, yPos + 40, summaryWidth, 30);

    // Ensure footer has enough space
    yPos += 100;

    // Footer
    pdfDoc.setFontSize(8);
    pdfDoc.setFont('helvetica', 'normal');
    pdfDoc.setTextColor(0, 0, 0);

    const prefix = 'Recibo gerado automaticamente por ';
    const linkText = 'Travell - Gerenciamento de Viagens e Excursões';
    const prefixWidth = pdfDoc.getTextWidth(prefix);
    const linkWidth = pdfDoc.getTextWidth(linkText);
    const totalWidth = prefixWidth + linkWidth;
    const startX = (pageWidth - totalWidth) / 2;

    pdfDoc.text(prefix, startX, pageHeight - 40);
    pdfDoc.setTextColor(0, 0, 255);
    pdfDoc.link(startX + prefixWidth, pageHeight - 45, linkWidth, 10, { url: 'https://travell.com.br' });
    pdfDoc.text(linkText, startX + prefixWidth, pageHeight - 40);

    // Save the PDF
    const filename = `reserva_hotel_${reservation.payerName.replace(/\s+/g, '_')}_${formatDate(reservation.checkInDate)}.pdf`;
    pdfDoc.save(filename);
};