import React from 'react';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    Typography,
    Box,
    alpha,
    IconButton
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Home as HomeIcon,
    EditRoad as EditRoadIcon,
    People as PeopleIcon,
    DirectionsBus as DirectionsBusIcon,
    EventNote as EventNoteIcon,
    Payment as PaymentIcon,
    ManageAccounts as ManageAccountsIcon,
    AccountBalanceWallet as AccountBalanceWalletIcon,
    CurrencyExchange as CurrencyExchangeIcon,
    AirlineSeatReclineNormal as AirlineSeatReclineNormalIcon,
    Equalizer as EqualizerIcon,
    Description as DescriptionIcon,
    CalendarMonth as CalendarMonthIcon,
    Hotel as HotelIcon,
    ExpandLess,
    ExpandMore,
    KeyboardArrowRight
} from '@mui/icons-material';

import { useAuth } from '../../core/contexts/useAuthState';
import { usePermissions } from '../../hooks/usePermissions';

const MenuItem = ({ icon: Icon, label, to, selected, onClick, nested = false }) => {
    const handleClick = (event) => {
        // Handle middle click (button 1)
        if (event.button === 1) {
            event.preventDefault();
            // Open in new tab while preserving relative path
            const baseUrl = window.location.origin;
            window.open(baseUrl + to, '_blank');
            return;
        }

        // Handle left click normally
        if (event.button === 0) {
            onClick(event);
        }
    };

    return (
        <ListItem
            onMouseDown={handleClick} // Using onMouseDown instead of onClick to catch middle click
            selected={selected}
            sx={{
                minHeight: 32,
                pl: nested ? 3 : 1.5,
                py: 0.5,
                borderRadius: '8px',
                mx: '4px',
                position: 'relative',
                transition: 'all 0.2s ease-in-out',
                cursor: 'pointer',
                ...(selected && {
                    backgroundColor: 'action.hover',
                }),
                '&:hover': {
                    backgroundColor: 'action.hover'
                }
            }}
        >
            {Icon && (
                <ListItemIcon sx={{ minWidth: 36 }}>
                    <Icon fontSize="small" />
                </ListItemIcon>
            )}
            <ListItemText
                secondary={label}
                secondaryTypographyProps={{
                    fontWeight: selected ? 500 : 400
                }}
            />
        </ListItem>
    );
};

const SubMenu = ({ icon: Icon, label, items, isExpanded }) => {
    const [open, setOpen] = React.useState(false);
    const location = useLocation();

    React.useEffect(() => {
        const isChildSelected = items.some(item => location.pathname === item.to);
        if (isChildSelected) setOpen(true);
    }, [location.pathname, items]);

    if (!isExpanded) return null;

    return (
        <>
            <ListItem
                button
                onClick={() => setOpen(!open)}
                sx={{
                    minHeight: 32,
                    py: 0.5,
                    pl: 1.5,
                    borderRadius: '8px',
                    mx: '4px',
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: 'action.hover'
                    }
                }}
            >
                <ListItemIcon sx={{ minWidth: 36 }}>
                    <Icon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                    secondary={label}
                    secondaryTypographyProps={{
                        fontWeight: 400
                    }}
                />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open}>
                <List disablePadding>
                    {items}
                </List>
            </Collapse>
        </>
    );
};

const MenuSection = ({ title, items, renderItem }) => {
    const [isExpanded, setIsExpanded] = React.useState(true);

    return (
        <Box sx={{ mb: 1 }}>
            <ListItem
                sx={{
                    py: 0,
                    minHeight: 28,
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: (theme) => alpha(theme.palette.action.hover, 0.1)
                    }
                }}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <ListItemText
                    secondary={title}
                    secondaryTypographyProps={{
                        color: 'text.secondary',
                        fontWeight: 500,
                        fontSize: '0.8rem'
                    }}
                    sx={{ flex: 1, margin: 0 }}
                />
                <IconButton
                    size="small"
                    sx={{
                        p: 0,
                        transform: isExpanded ? 'rotate(90deg)' : 'none',
                        transition: 'transform 0.2s'
                    }}
                >
                    <KeyboardArrowRight fontSize="small" />
                </IconButton>
            </ListItem>
            <Collapse in={isExpanded}>
                <List disablePadding>
                    {items.map(item => renderItem(item, isExpanded))}
                </List>
            </Collapse>
        </Box>
    );
};

const SidebarNavigation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const { checkPermission } = usePermissions();

    const menuItems = [
        {
            title: 'Principal',
            items: [
                {
                    icon: EditRoadIcon,
                    label: "Viagens",
                    to: "/viagens",
                    permission: { module: 'travels', action: 'view' }
                },
                {
                    icon: EditRoadIcon,
                    label: "Viagens Fretadas",
                    to: "/viagens-fretadas",
                    permission: { module: 'travels_freight', action: 'view' }
                },
                {
                    icon: PeopleIcon,
                    label: "Passageiros",
                    to: "/passageiros",
                    permission: { module: 'passengers', action: 'view' }
                },
                {
                    icon: DirectionsBusIcon,
                    label: "Veículos",
                    permission: { module: 'vehicles', action: 'view' },
                    submenu: [
                        {
                            icon: DirectionsBusIcon,
                            label: "Meus Veículos",
                            to: "/veiculos",
                            permission: { module: 'vehicles', action: 'view' }
                        },
                        {
                            icon: CalendarMonthIcon,
                            label: "Agenda de Veículos",
                            to: "/veiculos/agenda",
                            permission: { module: 'vehicles', action: 'view' }
                        },
                        {
                            icon: DescriptionIcon,
                            label: "Documentos",
                            to: "/veiculos/documentos",
                            permission: { module: 'vehicles', action: 'view' }
                        },
                    ]
                },
                {
                    icon: AirlineSeatReclineNormalIcon,
                    label: "Layouts de Assentos",
                    to: "/layouts",
                    permission: { module: 'layouts', action: 'view' }
                },
                {
                    icon: HotelIcon,
                    label: "Reservas de Hotel",
                    to: "/hoteis",
                    permission: { module: 'hotels', action: 'view' }
                },
            ]
        },
        {
            title: 'Operações',
            items: [
                {
                    icon: EventNoteIcon,
                    label: "Pedidos e Reservas",
                    to: "/pedidos-e-reservas",
                    permission: { module: 'orders', action: 'view' }
                },
                {
                    icon: PaymentIcon,
                    label: "Pagamentos",
                    to: "/pagamentos",
                    permission: { module: 'orders', action: 'view' }
                },
            ]
        },
        {
            title: 'Relatórios',
            items: [
                {
                    icon: EqualizerIcon,
                    label: "Business Analytics",
                    to: "/ba",
                    permission: { module: 'bussinessanalytics', action: 'view' }
                },
                {
                    icon: CurrencyExchangeIcon,
                    label: "Contas a Receber",
                    to: "/contas/receber",
                    permission: { module: 'receivables', action: 'view' }
                },
                {
                    icon: CurrencyExchangeIcon,
                    label: "Contas a Pagar",
                    to: "/contas/pagar",
                    permission: { module: 'payables', action: 'view' }
                }
            ]
        },
        {
            title: 'Gestão',
            items: [
                {
                    icon: AccountBalanceWalletIcon,
                    label: "Contas Bancárias",
                    to: "/contas-bancarias",
                    permission: { module: 'bank_accounts', action: 'view' }
                },
                {
                    icon: ManageAccountsIcon,
                    label: "Usuários",
                    to: "/usuarios",
                    permission: { module: 'users', action: 'view' }
                },
            ]
        }
    ];

    const isItemAccessible = (item) => {
        // Se for owner, tem acesso total
        if (currentUser?.role === 'owner') return true;

        // Se não tiver permissão definida, torna o item acessível
        if (!item.permission) return true;

        // Verifica permissão para o item usando o hook usePermissions
        return checkPermission(item.permission.module, item.permission.action);
    };

    const accessibleMenuItems = menuItems.filter(section =>
        section.items.some(item => {
            // Para itens com submenu, verifica se algum subitem é acessível
            if (item.submenu) {
                return item.submenu.some(isItemAccessible) || isItemAccessible(item);
            }
            return isItemAccessible(item);
        })
    );

    const renderItem = (item, isExpanded) => {
        // Se for owner, tem acesso total
        if (currentUser?.role === 'owner') {
            if (item.submenu) {
                return (
                    <SubMenu
                        key={item.label}
                        icon={item.icon}
                        label={item.label}
                        isExpanded={isExpanded}
                        items={item.submenu.map(subItem => renderItem(subItem, isExpanded))}
                    />
                );
            }
            return (
                <MenuItem
                    key={item.to}
                    icon={item.icon}
                    label={item.label}
                    to={item.to}
                    selected={location.pathname === item.to}
                    onClick={() => navigate(item.to)}
                />
            );
        }

        // Verifica permissão para itens normais
        if (isItemAccessible(item)) {
            if (item.submenu) {
                const accessibleSubItems = item.submenu.filter(isItemAccessible);

                if (accessibleSubItems.length === 0) return null;

                return (
                    <SubMenu
                        key={item.label}
                        icon={item.icon}
                        label={item.label}
                        isExpanded={isExpanded}
                        items={accessibleSubItems.map(subItem => renderItem(subItem, isExpanded))}
                    />
                );
            }
            return (
                <MenuItem
                    key={item.to}
                    icon={item.icon}
                    label={item.label}
                    to={item.to}
                    selected={location.pathname === item.to}
                    onClick={() => navigate(item.to)}
                />
            );
        }
        return null;
    };

    return (
        <Box sx={{ py: 0 }}>
            {accessibleMenuItems.map((section) => (
                <MenuSection
                    key={section.title}
                    title={section.title}
                    items={section.items}
                    renderItem={renderItem}
                />
            ))}
        </Box>
    );
};

export default SidebarNavigation;