import React, { useState, useEffect } from 'react';
import { Box, Chip } from '@mui/material';
import { PersonOutline } from '@mui/icons-material';
import {
    getUnallocatedPassengersCount,
    getAllocatedPassengersCount
} from '../services/AccommodationService';

const AllocationSummary = ({ travelId }) => {
    const [summary, setSummary] = useState({
        unallocatedPassengers: 0,
        allocatedPassengers: 0
    });

    useEffect(() => {
        const fetchCounts = async () => {
            try {
                const [unallocated, allocated] = await Promise.all([
                    getUnallocatedPassengersCount(travelId),
                    getAllocatedPassengersCount(travelId)
                ]);

                setSummary({
                    unallocatedPassengers: unallocated,
                    allocatedPassengers: allocated
                });
            } catch (error) {
                console.error('Error fetching counts:', error);
            }
        };

        fetchCounts();
    }, [travelId]);

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 2,
            p: 2
        }}>
            <Chip
                icon={<PersonOutline />}
                label={`Alocados: ${summary.allocatedPassengers}`}
                color="primary"
                variant="outlined"
                sx={{
                    '& .MuiChip-label': {
                        fontSize: '0.875rem'
                    }
                }}
            />
            <Chip
                icon={<PersonOutline />}
                label={`Não Alocados: ${summary.unallocatedPassengers}`}
                color="default"
                variant="outlined"
                sx={{
                    '& .MuiChip-label': {
                        fontSize: '0.875rem'
                    }
                }}
            />
        </Box>
    );
};

export default AllocationSummary;