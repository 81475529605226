import React, { useState } from 'react';
import {
  Grid,
  Typography,
  TextField,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  IconButton,
  Alert,
  AlertTitle,
  Autocomplete,
  Tooltip,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ChildCareIcon from '@mui/icons-material/ChildCare';

import { formatCPF, formatRG } from './../../../shared/utils/utils';

const PassengerSelection = ({
  reservations,
  passengers,
  loadingPassengers,
  duplicateWarnings,
  underageWarnings,
  handleInputChange,
  handlePayerChange,
  handleOpenFormDialog,
  handleOpenSeatSelection,
  handleRemoveReservation,
  handleAddNoSeatReservation,
  editingReservation,
  handleLapPassengerChange, // New prop for handling lap passenger toggle
}) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [reservationToRemoveIndex, setReservationToRemoveIndex] = useState(null);
  const theme = useTheme();

  const handleOpenConfirmModal = (index) => {
    if (editingReservation) {
      setReservationToRemoveIndex(index);
      setOpenConfirmModal(true);
    } else {
      handleRemoveReservation(index); // Remove localmente
    }
  };

  const handleConfirmCancel = () => {
    if (reservationToRemoveIndex !== null) {
      handleRemoveReservation(reservationToRemoveIndex);
    }
    setOpenConfirmModal(false); // Fechar o modal após confirmação
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
    setReservationToRemoveIndex(null);
  };

  const handleLapPassengerToggle = (index) => {
    if (handleLapPassengerChange) {
      handleLapPassengerChange(index);
    }
  };

  return (
    <Grid container spacing={2}>
      {reservations.map((reservation, index) => (
        <React.Fragment key={reservation.id || index}>
          <Grid item xs={12} container alignItems="center">
            {/* Botão de Remoção */}
            {reservations.length > 1 && (
              <Tooltip title={reservation.status === 'Cancelada' ? 'Reserva já cancelada' : 'Remover reserva'}>
                <span>
                  <IconButton
                    onClick={() => handleOpenConfirmModal(index)}
                    disabled={reservation.status === 'Cancelada'}
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}

            {/* Título do Assento */}
            <Typography
              variant="subtitle1"
              sx={{ ml: 1, color: theme.palette.text.primary }}
            >
              {reservation.numeroAssento
                ? `Assento ${reservation.numeroAssento}`
                : 'Reserva sem assento'}
            </Typography>

            {/* Botão de Trocar Assento */}
            <Tooltip title="Trocar assento">
              <IconButton
                onClick={() => handleOpenSeatSelection(index)}
                disabled={reservation.status === 'Cancelada'}
                sx={{
                  color: theme.palette.primary.main,
                  ml: 2,
                }}
              >
                <SwapHorizIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          {/* Avisos de Duplicidade */}
          {duplicateWarnings[index] && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 12px',
                  border: `1px dashed ${theme.palette.warning.main}`,
                  borderRadius: 1,
                  backgroundColor: theme.palette.warning.light,
                  opacity: 0.8,
                }}
              >
                <WarningIcon />
                <Typography variant="body2">
                  Aviso: Este passageiro já está alocado nesta viagem.
                </Typography>
              </Box>
            </Grid>
          )}

          {/* Avisos de Menor de Idade */}
          {underageWarnings[index] && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 12px',
                  border: `1px dashed ${theme.palette.warning.main}`,
                  borderRadius: 1,
                  backgroundColor: theme.palette.warning.light,
                  opacity: 0.8,
                }}
              >
                <WarningIcon />
                <Typography variant="body2">
                  Aviso: O passageiro selecionado é menor de idade.
                </Typography>
              </Box>
            </Grid>
          )}

          {/* Passenger Selection and Additional Options Row */}
          <Grid item xs={12} container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={passengers}
                getOptionLabel={(option) => {
                  let label = `${option.nome}`;
                  if (option.cpf) {
                    label += ` - CPF: ${formatCPF(option.cpf)}`;
                  }
                  if (option.estrangeiro) {
                    label += ` - Passaporte: ${option.passaporte}`;
                  } else {
                    label += ` - RG: ${formatRG(option.rg)}`;
                  }
                  return label;
                }}
                loading={loadingPassengers}
                value={passengers.find((p) => p.id === reservation.passengerId) || null}
                onChange={(event, newValue) => handleInputChange(index, event, newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Buscar Passageiro"
                    fullWidth
                    required
                    disabled={reservation.status === 'Cancelada'}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                          <Tooltip title="Adicionar Novo Passageiro">
                            <IconButton
                              onClick={handleOpenFormDialog}
                              sx={{ color: theme.palette.primary.main }}
                            >
                              <PersonAddIcon />
                            </IconButton>
                          </Tooltip>
                          {params.InputProps.startAdornment}
                        </Box>
                      ),
                      endAdornment: (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {loadingPassengers ? (
                            <CircularProgress color="inherit" size={20} sx={{ mr: 1 }} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Box>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            {/* Payer and Lap Passenger Checkboxes */}
            <Grid item xs={12} md={6} container alignItems="center" spacing={2}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={reservation.pagador}
                      onChange={() => handlePayerChange(index)}
                      name="pagador"
                      sx={{ color: theme.palette.primary.main }}
                      disabled={reservation.status === 'Cancelada'}
                    />
                  }
                  label="É o pagador do pedido"
                  sx={{ color: theme.palette.text.primary }}
                />
              </Grid>

              <Grid item>
                <Tooltip title="Passageiro ocupante de colo">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={reservation.lapPassenger || false}
                        onChange={() => handleLapPassengerToggle(index)}
                        name="lapPassenger"
                        sx={{ color: theme.palette.text.secondary }}
                        disabled={reservation.status === 'Cancelada'}
                      />
                    }
                    label="Ocupante de colo"
                    sx={{ color: theme.palette.text.primary }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>

          {/* Status de Cancelamento */}
          {reservation.status === 'Cancelada' && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 12px',
                  border: `1px dashed ${theme.palette.warning.main}`,
                  borderRadius: 1,
                  backgroundColor: theme.palette.warning.light,
                  opacity: 0.8,
                }}
              >
                <WarningIcon />
                Esta reserva está cancelada e não pode ser modificada.
              </Box>
            </Grid>
          )}
        </React.Fragment>
      ))}

      {/* Botão para Adicionar Reservas Sem Assento */}
      <Grid item xs={12}>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          variant="outlined"
          color="primary"
          onClick={handleAddNoSeatReservation}
        >
          Adicionar Reserva sem assento
        </Button>
      </Grid>

      {/* Modal de Confirmação de Cancelamento */}
      <Dialog
        open={openConfirmModal}
        onClose={handleCloseConfirmModal}
      >
        <DialogTitle>Confirmar Cancelamento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Esta ação irá cancelar esta reserva, tem certeza que deseja prosseguir? Essa ação não pode ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmModal} variant="outlined" sx={{ borderRadius: '50px' }}>
            Voltar
          </Button>
          <Button onClick={handleConfirmCancel} variant="contained" color="error" sx={{ borderRadius: '50px' }} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default PassengerSelection;