import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Image from '../../assets/404.svg';

const NotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '80vh',
                padding: 3,
                textAlign: 'center'
            }}
        >
            <Box
                component="img"
                src={Image}
                alt="404 - Página não encontrada"
                sx={{
                    width: { xs: '80%', sm: '50%', md: '40%' },
                    maxWidth: '400px',
                    height: 'auto',
                    marginBottom: 4
                }}
            />

            <Typography
                variant="h4"
                component="h1"
                sx={{
                    marginBottom: 3,
                    fontWeight: 500
                }}
            >
                Página não encontrada
            </Typography>

            <Typography
                variant="body1"
                sx={{
                    marginBottom: 4,
                    maxWidth: 'sm',
                    color: 'text.secondary'
                }}
            >
                A página que você está procurando não existe ou foi movida.
                Por favor, verifique o endereço ou retorne à página inicial.
            </Typography>

            <Button
                variant="contained"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
                sx={{
                    borderRadius: '50px',
                    padding: '10px 24px',
                    fontSize: '1rem'
                }}
            >
                Voltar
            </Button>
        </Box>
    );
};

export default NotFoundPage;