import React from 'react';
import {
    Grid, Typography, Button, Box, Divider, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, IconButton, InputAdornment
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

export const PaymentsList = ({
    payments,
    paymentCurrencyInputs,
    handlePaymentChange,
    addPayment,
    removePayment,
    setPaymentCurrencyInputs
}) => {
    const formatCurrency = (value) => {
        // Remove tudo que não é número
        let numericValue = value.replace(/\D/g, '');

        // Converte para número e formata
        const floatValue = parseFloat(numericValue) / 100;
        return floatValue.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    const handleValueChange = (index, e) => {
        let value = e.target.value;

        // Remove tudo que não é número
        value = value.replace(/\D/g, '');

        // Se estiver vazio, define como zero
        if (!value) value = '0';

        // Formata para exibição
        const displayValue = formatCurrency(value);

        // Atualiza os estados
        const newInputs = [...paymentCurrencyInputs];
        newInputs[index] = {
            ...newInputs[index],
            value: displayValue
        };
        setPaymentCurrencyInputs(newInputs);
        handlePaymentChange(index, 'amount', displayValue);
    };

    return (
        <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="subtitle1">
                    Pagamentos
                </Typography>
                <Button
                    startIcon={<AddIcon />}
                    onClick={addPayment}
                    variant="outlined"
                    size="small"
                >
                    Adicionar Pagamento
                </Button>
            </Box>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell>Valor</TableCell>
                            <TableCell>Método</TableCell>
                            <TableCell width={50}>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payments.map((payment, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <TextField
                                        type="date"
                                        fullWidth
                                        value={payment.date}
                                        onChange={(e) => handlePaymentChange(index, 'date', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        value={paymentCurrencyInputs[index]?.value || '0,00'}
                                        onChange={(e) => handleValueChange(index, e)}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">R$</InputAdornment>
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        select
                                        fullWidth
                                        value={payment.paymentMethod}
                                        onChange={(e) => handlePaymentChange(index, 'paymentMethod', e.target.value)}
                                        SelectProps={{ native: true }}
                                    >
                                        <option value="">Selecione...</option>
                                        <option value="Dinheiro">Dinheiro</option>
                                        <option value="Pix">Pix</option>
                                        <option value="Cartão">Cartão</option>
                                        <option value="Boleto">Boleto</option>
                                    </TextField>
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        size="small"
                                        onClick={() => removePayment(index)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};