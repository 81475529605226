import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './core/contexts/useAuthState';
import { DrawerProvider } from './core/contexts/DrawerContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { ProtectedRoute } from './core/contexts/ProtectedRoute';
import { usePermissions } from './hooks/usePermissions';
import getLPTheme from './shared/theme/getLPTheme';

// Page Imports
import LoginPage from './pages/Login/LoginPage';
import MyAccountPage from './pages/MyAccount/MyAccountPage';
import HelpCenterPage from './pages/HelpCenter/HelpCenterPage';
import LandingPage from './pages/LandingPage/LandingPage';
import PendingApprovalPage from './pages/PendingApproval/PendingApprovalPage';
import Layout from './shared/common/Layout';
import TravelLoading from './shared/common/TravelLoading';
import UpdateAgencyPage from './pages/UpdateAgency/UpdateAgencyPage';
import NotFoundPage from './pages/NotFound/NotFoundPage';
import { PERMISSIONS, PUBLIC_ROUTES } from './config/permissions';

import './App.css';

function AuthenticatedApp({ toggleColorMode, mode }) {
  const { currentUser, loading } = useAuth();
  const { getFirstAccessibleRoute } = usePermissions();
  const location = useLocation();

  const shouldShowSidebar = !PUBLIC_ROUTES.includes(location.pathname);
  const shouldHideLogout = PUBLIC_ROUTES.includes(location.pathname);

  if (loading) {
    return <TravelLoading />;
  }

  const isRootRoute = location.pathname === '/';

  return (
    <>
      {!isRootRoute ? (
        <Layout
          toggleColorMode={toggleColorMode}
          mode={mode}
          showSidebar={shouldShowSidebar}
          hideLogout={shouldHideLogout}
        >
          <Routes>
            {/* Add this new route for /home */}
            <Route
              path="/home"
              element={<Navigate to="/" replace />}
            />
            
            {/* Public Routes */}
            <Route path="*" element={<NotFoundPage />} />
            <Route
              path="/"
              element={
                currentUser
                  ? <Navigate to={getFirstAccessibleRoute()} />
                  : <LandingPage />
              }
            />
            <Route
              path="/login"
              element={
                !currentUser
                  ? <LoginPage />
                  : <Navigate to={getFirstAccessibleRoute()} />
              }
            />
            <Route path="/pendente-aprovacao" element={<PendingApprovalPage />} />
            <Route path="/central-ajuda" element={<HelpCenterPage />} />

            {/* Basic Protected Routes */}
            <Route path="/conta" element={<ProtectedRoute><MyAccountPage /></ProtectedRoute>} />
            <Route path="/agencia" element={<ProtectedRoute><UpdateAgencyPage /></ProtectedRoute>} />

            {/* Module-Specific Protected Routes */}
            {Object.values(PERMISSIONS).map(module =>
              module.routes.map(route => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <ProtectedRoute>
                      {route.component}
                    </ProtectedRoute>
                  }
                />
              ))
            )}
          </Routes>
        </Layout>
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              currentUser
                ? <Navigate to={getFirstAccessibleRoute()} />
                : <LandingPage />
            }
          />
        </Routes>
      )}
    </>
  );
}

export default function App() {
  const [mode, setMode] = useState('light');
  const [showCustomTheme, setShowCustomTheme] = useState(true);

  const LPTheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    } else {
      const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode);
  };

  return (
    <ThemeProvider theme={showCustomTheme ? LPTheme : defaultTheme}>
      <CssBaseline enableColorScheme />
      <Router>
        <AuthProvider>
          <DrawerProvider>
            <AuthenticatedApp toggleColorMode={toggleColorMode} mode={mode} />
          </DrawerProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}