import React, { useState } from 'react';
import {
    Grid, Card, CardContent, Typography, Box, IconButton,
    Tooltip, Paper, Pagination, TextField, InputAdornment, Button,
    Menu, MenuItem
} from '@mui/material';
import {
    Edit as EditIcon, PersonAdd as PersonAddIcon,
    Search as SearchIcon, PictureAsPdf as PdfIcon,
    Add as AddIcon, MoreVert as MoreVertIcon,
    Delete as DeleteIcon, Info as InfoIcon
} from '@mui/icons-material';

import RoomDetails from './RoomDetails';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';

import { getRoomAllocations } from '../services/AccommodationService';
import { exportAccommodationToPDF } from './exportAccommodationToPDF';

const HotelList = ({
    hotels,
    travel,
    onEditHotel,
    onDeleteHotel,
    onManageAllocation,
    onAddRoom,
    onEditRoom,
    onDeleteRoom,
    checkPermission
}) => {
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [hotelMenuAnchor, setHotelMenuAnchor] = useState(null);
    const [activeRoom, setActiveRoom] = useState(null);
    const [activeHotel, setActiveHotel] = useState(null);
    const [deleteHotelConfirmOpen, setDeleteHotelConfirmOpen] = useState(false);
    const [deleteRoomConfirmOpen, setDeleteRoomConfirmOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const roomsPerPage = 8;

    if (hotels.length === 0) {
        return (
            <Paper
                elevation={0}
                sx={{
                    p: 2,
                    mt: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}
            >
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    Nenhum hotel encontrado
                </Typography>
                <Typography variant="caption" color="textSecondary">
                    {checkPermission('travels_accommodations', 'create')
                        ? 'Adicione um novo hotel para começar.'
                        : 'Não há hotéis cadastrados para esta viagem.'}
                </Typography>
            </Paper>
        );
    }

    const handleExport = async (hotel) => {
        const hotelWithAllocations = {
            ...hotel,
            rooms: await Promise.all(
                hotel.rooms.map(async (room) => {
                    const allocations = await getRoomAllocations(room.id);
                    return { ...room, allocations };
                })
            )
        };

        exportAccommodationToPDF(hotelWithAllocations, travel);
    };

    const handleMenuOpen = (event, room) => {
        event.stopPropagation();
        setMenuAnchor(event.currentTarget);
        setActiveRoom(room);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
        setActiveRoom(null);
    };

    const handleHotelMenuOpen = (event, hotel) => {
        event.stopPropagation();
        setHotelMenuAnchor(event.currentTarget);
        setActiveHotel(hotel);
    };

    const handleHotelMenuClose = () => {
        setHotelMenuAnchor(null);
        setActiveHotel(null);
    };

    const handleEditRoom = () => {
        onEditRoom(activeRoom);
        handleMenuClose();
    };

    const handleDeleteRoom = () => {
        setDeleteRoomConfirmOpen(true);
    };

    const handleEditHotel = () => {
        onEditHotel(activeHotel);
        handleHotelMenuClose();
    };

    const handleDeleteHotel = () => {
        setDeleteHotelConfirmOpen(true);
    };

    const handleDeleteHotelConfirm = async () => {
        try {
            setIsDeleting(true);
            await onDeleteHotel(activeHotel);
            handleHotelMenuClose();
        } finally {
            setIsDeleting(false);
            setDeleteHotelConfirmOpen(false);
        }
    };

    const handleDeleteRoomConfirm = async () => {
        try {
            setIsDeleting(true);
            await onDeleteRoom(activeRoom);
            handleMenuClose();
        } finally {
            setIsDeleting(false);
            setDeleteRoomConfirmOpen(false);
        }
    };

    const renderRoomActions = (room) => (
        <Box>
            {room.notes && (
                <Tooltip
                    title={room.notes}
                    placement="left"
                    sx={{ maxWidth: 300 }}
                >
                    <IconButton
                        size="small"
                        sx={{ padding: 0.5 }}
                    >
                        <InfoIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip title="Opções" placement="left">
                <IconButton
                    size="small"
                    sx={{ padding: 0.5 }}
                    onClick={(e) => handleMenuOpen(e, room)}
                >
                    <MoreVertIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Tooltip title="Gerenciar alocações" placement="left">
                <IconButton
                    onClick={() => onManageAllocation(room)}
                    size="small"
                    sx={{ padding: 0.5, ml: 0.5 }}
                >
                    <PersonAddIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Box>
    );

    const renderHotelActions = (hotel) => (
        <Box>
            {hotel.notes && (
                <Tooltip
                    title={hotel.notes}
                    placement="top"
                    sx={{ maxWidth: 300 }}
                >
                    <IconButton size="small" sx={{ padding: 0.5 }}>
                        <InfoIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip title="Exportar para PDF" placement="top">
                <IconButton onClick={() => handleExport(hotel)} size="small" sx={{ padding: 0.5 }}>
                    <PdfIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Tooltip title="Opções" placement="top">
                <IconButton
                    size="small"
                    sx={{ padding: 0.5 }}
                    onClick={(e) => handleHotelMenuOpen(e, hotel)}
                >
                    <MoreVertIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Box>
    );

    const renderRooms = (hotel) => {
        const filteredRooms = hotel.rooms.filter(room =>
            room.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        const totalPages = Math.ceil(filteredRooms.length / roomsPerPage);
        const startIndex = (currentPage - 1) * roomsPerPage;
        const displayedRooms = filteredRooms.slice(startIndex, startIndex + roomsPerPage);

        return (
            <Box sx={{ mt: 1 }}>
                <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                    <TextField
                        size="small"
                        fullWidth
                        placeholder="Buscar quartos"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            ),
                            sx: { fontSize: '0.875rem' }
                        }}
                    />
                    {checkPermission('travels_accommodations', 'create') && (
                        <Button
                            variant="outlined"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={() => onAddRoom(hotel)}
                            sx={{ borderRadius: '50px', whiteSpace: 'nowrap' }}
                        >
                            Quarto
                        </Button>
                    )}
                </Box>

                <Grid container spacing={1}>
                    {displayedRooms.map((room) => (
                        <Grid item xs={12} key={room.id}>
                            <Card variant="outlined" sx={{ '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.02)' } }}>
                                <CardContent sx={{ p: '8px !important' }}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Box>
                                            <Typography variant="body2" sx={{
                                                fontWeight: 500,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                maxWidth: { xs: '120px', sm: '180px', md: '250px' }
                                            }}>
                                                {room.name}
                                            </Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                                <Tooltip title="Ocupação do quarto" placement="top">
                                                    <Typography variant="caption" sx={{
                                                        bgcolor: 'action.hover',
                                                        px: 0.5,
                                                        borderRadius: 0.5,
                                                        display: 'inline-flex'
                                                    }}>
                                                        {room.occupiedBeds}/{room.totalBeds}
                                                    </Typography>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                        {checkPermission('travels_accommodations', 'edit') && renderRoomActions(room)}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                {totalPages > 1 && (
                    <Box display="flex" justifyContent="center" mt={1}>
                        <Tooltip title="Navegar entre páginas" placement="top">
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={(_, page) => setCurrentPage(page)}
                                size="small"
                            />
                        </Tooltip>
                    </Box>
                )}
            </Box>
        );
    };

    return (
        <>
            <Grid container spacing={2}>
                {hotels.map((hotel) => (
                    <Grid item xs={12} md={6} lg={4} key={hotel.id}>
                        <Card elevation={0} sx={{ height: '100%' }}>
                            <CardContent sx={{ p: '12px !important' }}>
                                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                    <Box>
                                        <Typography variant="subtitle2" sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            maxWidth: { xs: '150px', sm: '250px' }
                                        }}>
                                            {hotel.name}
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary" sx={{
                                            display: 'block',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            maxWidth: { xs: '150px', sm: '250px' }
                                        }}>
                                            {hotel.address}
                                        </Typography>
                                    </Box>
                                    {renderHotelActions(hotel)}
                                </Box>

                                <Typography variant="caption" sx={{ display: 'block', mb: 0.5 }}>
                                    Quartos ({hotel.rooms.length})
                                </Typography>

                                {renderRooms(hotel)}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            {/* Room Menu */}
            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={handleMenuClose}
            >
                {checkPermission('travels_accommodations', 'edit') && (
                    <MenuItem onClick={handleEditRoom}>
                        <EditIcon fontSize="small" sx={{ mr: 1 }} />
                        Editar
                    </MenuItem>
                )}
                {checkPermission('travels_accommodations', 'delete') && (
                    <MenuItem onClick={handleDeleteRoom}>
                        <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
                        Excluir
                    </MenuItem>
                )}
            </Menu>

            {/* Hotel Menu */}
            <Menu
                anchorEl={hotelMenuAnchor}
                open={Boolean(hotelMenuAnchor)}
                onClose={handleHotelMenuClose}
            >
                {checkPermission('travels_accommodations', 'edit') && (
                    <MenuItem onClick={handleEditHotel}>
                        <EditIcon fontSize="small" sx={{ mr: 1 }} />
                        Editar
                    </MenuItem>
                )}
                {checkPermission('travels_accommodations', 'delete') && (
                    <MenuItem onClick={handleDeleteHotel} >
                        <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
                        Excluir
                    </MenuItem>
                )}
            </Menu>

            <RoomDetails
                open={!!selectedRoom}
                onClose={() => setSelectedRoom(null)}
                room={selectedRoom}
            />

            <ConfirmationDialog
                open={deleteHotelConfirmOpen}
                title="Confirmar Exclusão"
                message={`Tem certeza que deseja excluir o hotel "${activeHotel?.name}"? Esta ação não pode ser desfeita.`}
                onConfirm={handleDeleteHotelConfirm}
                onClose={() => setDeleteHotelConfirmOpen(false)}
                loading={isDeleting}
            />

            <ConfirmationDialog
                open={deleteRoomConfirmOpen}
                title="Confirmar Exclusão"
                message={`Tem certeza que deseja excluir o quarto "${activeRoom?.name}"? Esta ação não pode ser desfeita.`}
                onConfirm={handleDeleteRoomConfirm}
                onClose={() => setDeleteRoomConfirmOpen(false)}
                loading={isDeleting}
            />
        </>
    );
};

export default HotelList;