// LogoService.js
import { db } from '../../../firebaseConfig';
import { collection, doc, setDoc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';

const MAX_LOGO_SIZE = 2 * 1024 * 1024; // 2MB
const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/svg+xml'];

export const uploadAgencyLogo = async (agencyId, file) => {
  try {
    // Validações
    if (!file) throw new Error('Nenhum arquivo selecionado');
    if (!ALLOWED_TYPES.includes(file.type)) {
      throw new Error('Tipo de arquivo não permitido. Use JPG, PNG ou SVG.');
    }
    if (file.size > MAX_LOGO_SIZE) {
      throw new Error('Arquivo muito grande. Máximo permitido: 2MB');
    }

    // Converter arquivo para base64
    const base64 = await convertFileToBase64(file);
    
    // Criar documento na subcoleção de logos
    const logoDoc = doc(collection(db, 'agencies', agencyId, 'settings'), 'logo');
    
    await setDoc(logoDoc, {
      base64: base64,
      fileName: file.name,
      contentType: file.type,
      updatedAt: new Date().toISOString()
    });

    // Adicionar referência no documento principal da agência
    const agencyRef = doc(db, 'agencies', agencyId);
    await updateDoc(agencyRef, {
      hasLogo: true,
      'metadata.updatedAt': new Date().toISOString()
    });

    return base64;
  } catch (error) {
    console.error('Erro ao fazer upload do logo:', error);
    throw error;
  }
};

const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const getAgencyLogo = async (agencyId) => {
  try {
    const logoDoc = await getDoc(doc(collection(db, 'agencies', agencyId, 'settings'), 'logo'));
    
    if (!logoDoc.exists()) {
      return null;
    }

    return logoDoc.data();
  } catch (error) {
    console.error('Erro ao buscar logo da agência:', error);
    throw error;
  }
};

export const deleteAgencyLogo = async (agencyId) => {
  try {
    // Deletar documento da logo
    await deleteDoc(doc(collection(db, 'agencies', agencyId, 'settings'), 'logo'));
    
    // Atualizar referência no documento da agência
    const agencyRef = doc(db, 'agencies', agencyId);
    await updateDoc(agencyRef, {
      hasLogo: false,
      'metadata.updatedAt': new Date().toISOString()
    });

    return true;
  } catch (error) {
    console.error('Erro ao deletar logo:', error);
    throw error;
  }
};