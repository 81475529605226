import React from 'react';
import { Grid, TextField, Typography, Autocomplete } from '@mui/material';

export const HotelInformation = ({ formData, handleInputChange }) => {
  const roomTypeOptions = [
    'Quarto Standard',
    'Quarto Superior',
    'Quarto Luxo',
    'Suíte',
    'Suíte Executiva',
    'Suíte Presidencial',
    'Quarto Família',
    'Bangalô'
  ];

  const pensionTypeOptions = [
    'Sem Pensão',
    'Café da Manhã',
    'Meia Pensão',
    'Pensão Completa',
    'All Inclusive'
  ];

  const handleAutocompleteChange = (name) => (event, newValue) => {
    handleInputChange({
      target: {
        name,
        value: newValue || ''
      }
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="subtitle1" gutterBottom>
          Informações do Hotel
        </Typography>
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          label="ID do Hotel"
          name="hotelId"
          value={formData.hotelId || ''}
          onChange={handleInputChange}
          placeholder="ID interno do hotel"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Nome do Hotel"
          name="hotelName"
          value={formData.hotelName}
          onChange={handleInputChange}
          required
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Autocomplete
          freeSolo
          options={roomTypeOptions}
          value={formData.roomType}
          onChange={handleAutocompleteChange('roomType')}
          onInputChange={(event, newValue) => {
            handleInputChange({
              target: {
                name: 'roomType',
                value: newValue || ''
              }
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tipo de Acomodação"
              required
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Autocomplete
          freeSolo
          options={pensionTypeOptions}
          value={formData.pensionType}
          onChange={handleAutocompleteChange('pensionType')}
          onInputChange={(event, newValue) => {
            handleInputChange({
              target: {
                name: 'pensionType',
                value: newValue || ''
              }
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tipo de Pensão"
              required
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={8}>
        <TextField
          fullWidth
          label="Endereço do Hotel"
          name="hotelAddress"
          value={formData.hotelAddress || ''}
          onChange={handleInputChange}
          placeholder="Rua, número, bairro, cidade - estado"
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          label="Telefone do Hotel"
          name="hotelPhone"
          value={formData.hotelPhone || ''}
          onChange={handleInputChange}
          placeholder="(00) 0000-0000"
        />
      </Grid>

      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12} md={6} container spacing={2}>
          <Grid item xs={12} sm={7}>
            <TextField
              fullWidth
              label="Data Check-in"
              name="checkInDate"
              type="date"
              value={formData.checkInDate}
              onChange={handleInputChange}
              required
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              fullWidth
              label="Hora Check-in"
              name="checkInTime"
              type="time"
              value={formData.checkInTime}
              onChange={handleInputChange}
              required
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} container spacing={2}>
          <Grid item xs={12} sm={7}>
            <TextField
              fullWidth
              label="Data Check-out"
              name="checkOutDate"
              type="date"
              value={formData.checkOutDate}
              onChange={handleInputChange}
              required
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              fullWidth
              label="Hora Check-out"
              name="checkOutTime"
              type="time"
              value={formData.checkOutTime}
              onChange={handleInputChange}
              required
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};