import React from 'react';
import {
    Grid, Typography, Button, Box, Divider,
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, TextField, IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

export const GuestsList = ({
    guests,
    handleGuestChange,
    addGuest,
    removeGuest
}) => (
    <Grid item xs={12}>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="subtitle1">
                Hóspedes
            </Typography>
            <Button
                startIcon={<AddIcon />}
                onClick={addGuest}
                variant="outlined"
                size="small"
            >
                Adicionar Hóspede
            </Button>
        </Box>
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Tipo</TableCell>
                        <TableCell>Nome</TableCell>
                        <TableCell>Data de Nascimento</TableCell>
                        <TableCell width={50}>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {guests.map((guest, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <TextField
                                    select
                                    fullWidth
                                    value={guest.type}
                                    onChange={(e) => handleGuestChange(index, 'type', e.target.value)}
                                    SelectProps={{ native: true }}
                                >
                                    <option value="Adulto">Adulto</option>
                                    <option value="Criança">Criança</option>
                                </TextField>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    value={guest.name}
                                    onChange={(e) => handleGuestChange(index, 'name', e.target.value)}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    type="date"
                                    fullWidth
                                    value={guest.birthDate}
                                    onChange={(e) => handleGuestChange(index, 'birthDate', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    size="small"
                                    onClick={() => removeGuest(index)}
                                    disabled={guests.length === 1}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Grid>
);