export const convertSvgToPng = async (svgData) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;

            ctx.drawImage(img, 0, 0);

            try {
                const pngData = canvas.toDataURL('image/png');
                resolve(pngData);
            } catch (error) {
                reject(error);
            }
        };

        img.onerror = reject;

        if (svgData.startsWith('data:image/svg+xml;base64,')) {
            img.src = svgData;
        } else {
            img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
        }
    });
};