import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatCPF, formatDate, formatTelefone } from '../../../shared/utils/utils';
import logoTravell from '../../../assets/logo-travell-combr.png';
import { auth, db } from '../../../firebaseConfig';
import { getDoc, doc as firestoreDoc } from 'firebase/firestore';
import { getAgencyLogo } from '../../../pages/UpdateAgency/services/LogoService';
import { convertSvgToPng } from '../../../shared/utils/convertSvgToPng';

export const exportAccommodationToPDF = async (hotel, travel) => {
    // Initialize document and get basic info
    const doc = new jsPDF('landscape', 'pt', 'a4');
    const { width: pageWidth, height: pageHeight } = doc.internal.pageSize;
    const margins = { top: 40, right: 20, left: 20, bottom: 30 };
    let startY = 70;

    // Get agency information
    const user = auth.currentUser;
    const userRef = firestoreDoc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    const agencyRef = firestoreDoc(db, 'agencies', userDoc.data().agencyId);
    const agencyDoc = await getDoc(agencyRef);
    const agencyData = agencyDoc.data();

    const ownerRef = firestoreDoc(db, 'users', agencyData.ownerId);
    const ownerDoc = await getDoc(ownerRef);
    const ownerPhone = ownerDoc.data().phone;

    // Get agency logo
    let logoData = null;
    try {
        logoData = await getAgencyLogo(userDoc.data().agencyId);
    } catch (error) {
        console.warn('Unable to fetch agency logo:', error);
    }

    // Load Travell logo
    const travellImg = new Image();
    travellImg.src = logoTravell;

    await new Promise((resolve, reject) => {
        travellImg.onload = resolve;
        travellImg.onerror = reject;
    });

    // Utility functions
    const getImageFormat = (base64String, contentType) => {
        if (contentType) {
            if (contentType.includes('jpeg') || contentType.includes('jpg')) return 'JPEG';
            if (contentType.includes('png')) return 'PNG';
            if (contentType.includes('svg')) return 'SVG';
        }

        if (base64String.startsWith('data:image/jpeg') || base64String.startsWith('data:image/jpg')) return 'JPEG';
        if (base64String.startsWith('data:image/png')) return 'PNG';
        if (base64String.startsWith('data:image/svg')) return 'SVG';

        return 'PNG';
    };

    // Handle agency logo display
    if (logoData && logoData.base64) {
        try {
            let imageData = logoData.base64;
            let imageFormat = getImageFormat(imageData, logoData.contentType);

            if (imageFormat === 'SVG') {
                imageData = await convertSvgToPng(logoData.base64);
                imageFormat = 'PNG';
            }

            const img = new Image();
            img.src = imageData;

            await new Promise((resolve, reject) => {
                img.onload = resolve;
                img.onerror = (error) => {
                    console.error('Error loading image:', error);
                    reject(error);
                };
            });

            // Calculate logo dimensions (max height 60px for landscape)
            const maxHeight = 60;
            const aspectRatio = img.width / img.height;
            const logoHeight = Math.min(maxHeight, img.height);
            const logoWidth = logoHeight * aspectRatio;

            // Position agency logo at top right
            const logoX = pageWidth - logoWidth - 40;
            const logoY = 15;

            doc.addImage(
                imageData,
                imageFormat,
                logoX,
                logoY,
                logoWidth,
                logoHeight
            );

            // Add agency name and phone
            doc.setFontSize(10);
            doc.setFont('helvetica', 'bold');
            const agencyPhone = ownerPhone ? formatTelefone(ownerPhone) : '';
            const agencyText = agencyData.name + (agencyPhone ? ` | ${agencyPhone}` : '');
            doc.text(agencyText, margins.left, 20);

        } catch (error) {
            console.error('Error adding agency logo to PDF:', error);
        }
    } else {
        // If no logo, still display agency name and phone
        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold');
        const agencyPhone = ownerPhone ? formatTelefone(ownerPhone) : '';
        const agencyText = agencyData.name + (agencyPhone ? ` | ${agencyPhone}` : '');
        doc.text(agencyText, margins.left, 20);
    }

    // Add travel details
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text('Detalhes da Viagem', margins.left, 35);

    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');

    doc.text(`Identificador: ${travel?.identificador || 'Não informado'} | Origem: ${travel?.origem || 'Não informado'} | Destino: ${travel?.destino || 'Não informado'}`, margins.left, 50);
    doc.text(`Data Ida: ${formatDate(travel?.dataIda)} às ${travel?.horarioIda || 'Não informado'} | ${travel?.somenteIda ? 'Somente Ida' : `Volta: ${formatDate(travel?.dataRetorno)} às ${travel?.horarioRetorno || 'Não informado'}`}`, margins.left, 65);
    doc.text(`Hotel: ${hotel.name} | Endereço: ${hotel.address}`, margins.left, 80);

    if (hotel.contactName || hotel.contactPhone) {
        doc.text(`Contato Hospedagem: ${hotel.contactName || 'Não informado'} - ${hotel.contactPhone || 'Não informado'} | Check-in: ${hotel.checkInTime || 'Não informado'} - Check-out: ${hotel.checkOutTime || 'Não informado'}`, margins.left, 95);
        startY = 120;
    } else {
        startY = 105;
    }

    const addRoomTable = (room, startY) => {
        doc.setDrawColor(25, 118, 210);
        doc.setFillColor(240, 240, 240);
        doc.rect(margins.left, startY - 15, pageWidth - margins.left - margins.right, 25, 'F');

        doc.setFont('helvetica', 'bold');
        doc.setFontSize(12);
        const roomText = `${room.name} (${room.occupiedBeds}/${room.totalBeds} hóspedes)`;
        doc.text(roomText, margins.left + 10, startY);

        if (room.notes) {
            doc.setFontSize(9);
            doc.setFont('helvetica', 'italic');
            doc.text(`Observações: ${room.notes}`, margins.left + 300, startY);
        }

        const columns = [
            { header: 'Nome', dataKey: 'nome' },
            { header: 'CPF/Passaporte', dataKey: 'documento' },
            { header: 'RG', dataKey: 'rg' },
            { header: 'Data Nascimento', dataKey: 'dataNascimento' },
            { header: 'Idade', dataKey: 'idade' },
            { header: 'Endereço', dataKey: 'endereco' },
            { header: 'Telefone', dataKey: 'telefone' },
            { header: 'Informações', dataKey: 'informacoes' }
        ];

        const calculateAge = (birthDate) => {
            if (!birthDate) return 'Não informado';
            const today = new Date();
            const birth = new Date(birthDate);
            let age = today.getFullYear() - birth.getFullYear();
            const monthDiff = today.getMonth() - birth.getMonth();
            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
                age--;
            }
            return age.toString();
        };

        const wrapText = (text, maxLength) => {
            if (!text) return 'Não informado';
            if (text.length <= maxLength) return text;
            const words = text.split(' ');
            let lines = [''];
            let currentLine = 0;

            words.forEach(word => {
                if ((lines[currentLine] + ' ' + word).length <= maxLength) {
                    lines[currentLine] += (lines[currentLine] ? ' ' : '') + word;
                } else {
                    currentLine++;
                    lines[currentLine] = word;
                }
            });

            return lines.join('\n');
        };

        const rows = room.allocations?.map(allocation => {
            const passenger = allocation.passenger || {};
            const menorDeIdade = passenger.menorDeIdade ? 'Menor de Idade' : '';

            return {
                nome: passenger.nome || 'Não informado',
                documento: passenger.estrangeiro ?
                    `Passaporte: ${passenger.passaporte || 'Não informado'}` :
                    formatCPF(passenger.cpf) || 'Não informado',
                rg: passenger.estrangeiro ? '-' : (passenger.rg || 'Não informado'),
                dataNascimento: formatDate(passenger.dataNascimento),
                idade: calculateAge(passenger.dataNascimento),
                endereco: wrapText(passenger.endereco, 200),
                telefone: formatTelefone(passenger.telefone),
                informacoes: wrapText(menorDeIdade, 50)
            };
        }) || [];

        doc.autoTable({
            startY: startY + (room.notes ? 25 : 15),
            head: [columns.map(col => col.header)],
            body: rows.map(row => columns.map(col => row[col.dataKey])),
            theme: 'striped',
            margin: margins,
            styles: {
                fontSize: 9,
                cellPadding: 4,
                halign: 'center'
            },
            headStyles: {
                fillColor: [25, 118, 210],
                textColor: [255, 255, 255],
                fontStyle: 'bold'
            },
            alternateRowStyles: {
                fillColor: [240, 240, 240]
            },
            columnStyles: {
                nome: { cellWidth: 120, cellPadding: 6 },
                documento: { cellWidth: 100, cellPadding: 6 },
                rg: { cellWidth: 80, cellPadding: 6 },
                dataNascimento: { cellWidth: 80, cellPadding: 6 },
                idade: { cellWidth: 40, cellPadding: 6 },
                endereco: { cellWidth: 150, cellPadding: 6 },
                telefone: { cellWidth: 80, cellPadding: 6 },
                informacoes: { cellWidth: 80, cellPadding: 6 }
            },
            didDrawPage: function (data) {
                // Add page numbers
                doc.setFontSize(8);
                doc.setTextColor(40);
                let str = 'Página ' + doc.internal.getNumberOfPages();
                if (typeof doc.putTotalPages === 'function') {
                    str = str + ' de ' + doc.internal.getNumberOfPages();
                }
                doc.text(str, margins.left, pageHeight - 10);

                // Add Travell logo on each page
                const travellLogoWidth = 90;
                const travellLogoHeight = 25;
                const travellLogoX = pageWidth - travellLogoWidth - 20;
                const travellLogoY = pageHeight - travellLogoHeight - 10;

                doc.addImage(
                    travellImg,
                    'PNG',
                    travellLogoX,
                    travellLogoY,
                    travellLogoWidth,
                    travellLogoHeight
                );
                doc.link(travellLogoX, travellLogoY, travellLogoWidth, travellLogoHeight, { url: 'https://travell.com.br' });
            }
        });

        return doc.lastAutoTable.finalY + 50;
    };

    try {
        let currentY = startY;

        for (const room of hotel.rooms) {
            if (currentY + 200 > pageHeight - margins.bottom) {
                doc.addPage();
                currentY = margins.top;
            }
            currentY = addRoomTable(room, currentY);
        }

        const filename = `Hospedagem - Viagem:${travel?.identificador || 'sem-id'} - Hotel:${hotel.name} - ${agencyData.name}.pdf`;
        doc.save(filename);
    } catch (error) {
        console.error('Erro ao gerar PDF:', error);
    }
};