import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, Typography, Button, Card, CardContent, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip, Snackbar,
  Alert, CircularProgress, Chip, Dialog, DialogTitle, DialogContent,
  DialogContentText, DialogActions, TextField, InputAdornment, Collapse,
  FormControl, InputLabel, Select, MenuItem, Pagination
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useTheme } from '@mui/material/styles';
import TravelLoading from '../../shared/common/TravelLoading';

import HotelReservationForm from './HotelReservationForm';

import { generateHotelReceiptPDF } from './services/generateHotelReceiptPDF';
import {
  getPaginatedHotelReservations,
  addHotelReservation,
  updateHotelReservation,
  getHotelReservations,
  deleteHotelReservation
} from './services/HotelService';

const HotelReservationPage = () => {
  const theme = useTheme();
  const [reservations, setReservations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formOpen, setFormOpen] = useState(false);
  const [editingReservation, setEditingReservation] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [formLoading, setFormLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    reservation: null
  });

  useEffect(() => {
    fetchReservations();
  }, []);

  const fetchReservations = useCallback(async (page = 1) => {
    setLoading(true);
    try {
      const result = await getPaginatedHotelReservations(
        itemsPerPage,
        searchTerm,
        sortOrder,
        page
      );
      setReservations(result.reservations);
      setTotalItems(result.total);
    } catch (error) {
      showSnackbar('Erro ao carregar reservas', 'error');
    } finally {
      setLoading(false);
    }
  }, [itemsPerPage, searchTerm, sortOrder]);

  useEffect(() => {
    fetchReservations(1);
  }, [sortOrder, searchTerm]);

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const executeSearch = () => {
    setSearchTerm(searchInput);
    setCurrentPage(1);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      executeSearch();
    }
  };

  const handleClearSearch = () => {
    setSearchInput('');
    setSearchTerm('');
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    fetchReservations(value);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    setCurrentPage(1);
  };

  const handleOpenForm = () => {
    setEditingReservation(null);
    setFormOpen(true);
  };

  const handleCloseForm = () => {
    setFormOpen(false);
    setEditingReservation(null);
  };

  const handleEditReservation = (reservation) => {
    setEditingReservation(reservation);
    setFormOpen(true);
  };

  const handleSubmitReservation = async (formData, generateReceiptAfterSave = false) => {
    setFormLoading(true);
    try {
      let savedReservation;
      if (editingReservation) {
        savedReservation = await updateHotelReservation(editingReservation.id, formData);
        showSnackbar('Reserva atualizada com sucesso');
      } else {
        savedReservation = await addHotelReservation(formData);
        showSnackbar('Reserva criada com sucesso');
      }

      if (generateReceiptAfterSave) {
        await generateHotelReceiptPDF(savedReservation);
        showSnackbar('Reserva salva e recibo gerado com sucesso');
      }

      await fetchReservations();
      handleCloseForm();
    } catch (error) {
      showSnackbar(
        'Erro ao ' + (editingReservation ? 'atualizar' : 'criar') + ' reserva',
        'error'
      );
    } finally {
      setFormLoading(false);
    }
  };

  const handleGenerateReceipt = async (reservation) => {
    try {
      setFormLoading(true);
      await generateHotelReceiptPDF(reservation);
      showSnackbar('Recibo gerado com sucesso');
    } catch (error) {
      console.error('Error generating receipt:', error);
      showSnackbar('Erro ao gerar recibo', 'error');
    } finally {
      setFormLoading(false);
    }
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR');
  };

  const formatDateTime = (date, time) => {
    if (!date) return '';
    return `${formatDate(date)} ${time || ''}`;
  };

  const formatCurrency = (value) => {
    if (!value) return 'R$ 0,00';
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(value);
  };

  const getPaymentStatus = (reservation) => {
    const total = parseFloat(reservation.totalAmount) || 0;
    const paid = parseFloat(reservation.paidAmount) || 0;

    if (paid === 0) return 'Pendente';
    if (paid < total) return 'Parcial';
    if (paid === total) return 'Pago';
    return 'Verificar';
  };

  const handleDeleteClick = (reservation) => {
    setDeleteDialog({
      open: true,
      reservation
    });
  };

  const handleDeleteClose = () => {
    setDeleteDialog({
      open: false,
      reservation: null
    });
  };

  const handleDeleteConfirm = async () => {
    if (!deleteDialog.reservation) return;

    setFormLoading(true);
    try {
      await deleteHotelReservation(deleteDialog.reservation.id);
      showSnackbar('Reserva excluída com sucesso');
      await fetchReservations();
    } catch (error) {
      showSnackbar('Erro ao excluir reserva', 'error');
    } finally {
      setFormLoading(false);
      handleDeleteClose();
    }
  };

  const getStatusColor = (status) => {
    const statusColors = {
      'Pendente': 'error',
      'Parcial': 'warning',
      'Pago': 'success',
      'Verificar': 'info'
    };
    return statusColors[status] || 'default';
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <TravelLoading />
      </Box>
    );
  }

  return (
    <Box >
      <Box sx={{ display: 'flex', gap: 2, mb: 2, alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
        <Typography variant="h6">Reservas de Hotel</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenForm}
          sx={{ borderRadius: '50px' }}
        >
          Nova Reserva
        </Button>
        <Button
          variant="outlined"
          startIcon={<FilterListIcon />}
          onClick={() => setFiltersVisible(!filtersVisible)}
          sx={{ borderRadius: '50px' }}
        >
          {filtersVisible ? 'Ocultar Filtros' : 'Mostrar Filtros'}
        </Button>
      </Box>

      <Collapse in={filtersVisible}>
        <Box sx={{ display: 'flex', gap: 2, mb: 2, alignItems: 'center', flexWrap: 'wrap' }}>
          <FormControl variant="outlined" sx={{ minWidth: 200 }}>
            <InputLabel>Ordenar por</InputLabel>
            <Select
              value={sortOrder}
              onChange={handleSortChange}
              label="Ordenar por"
            >
              <MenuItem value="desc">Mais Recentes</MenuItem>
              <MenuItem value="asc">Mais Antigos</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Buscar"
            variant="outlined"
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Buscar por ID, Hotel, Pagador ou CPF"
            sx={{ flexGrow: 1 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchInput && (
                    <IconButton onClick={handleClearSearch} edge="end" size="small">
                      <ClearIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={executeSearch} edge="end" size="small">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Collapse>
      <Card>
        <CardContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Hotel</TableCell>
                  <TableCell>Acomodação</TableCell>
                  <TableCell>Pagador</TableCell>
                  <TableCell>Check-in</TableCell>
                  <TableCell>Check-out</TableCell>
                  <TableCell>Valor Total</TableCell>
                  <TableCell>Valor Pago</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reservations.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <Typography variant="body2" color="text.secondary">
                        Nenhuma reserva encontrada
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  reservations.map((reservation) => {
                    const paymentStatus = getPaymentStatus(reservation);
                    return (
                      <TableRow key={reservation.id}>
                        <TableCell>
                          <Typography variant="body2">{reservation.hotelName}</Typography>
                          {reservation.hotelId && (
                            <Typography variant="caption" color="text.secondary" display="block">
                              ID: {reservation.hotelId}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">{reservation.roomType}</Typography>
                          <Typography variant="caption" color="text.secondary" display="block">
                            {reservation.pensionType}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">{reservation.payerName}</Typography>
                          <Typography variant="caption" color="text.secondary" display="block">
                            {reservation.isForeignPayer ?
                              `Passaporte: ${reservation.payerPassport}` :
                              `CPF: ${reservation.payerDocument}`}
                          </Typography>
                        </TableCell>
                        <TableCell>{formatDateTime(reservation.checkInDate, reservation.checkInTime)}</TableCell>
                        <TableCell>{formatDateTime(reservation.checkOutDate, reservation.checkOutTime)}</TableCell>
                        <TableCell>{formatCurrency(reservation.totalAmount)}</TableCell>
                        <TableCell>{formatCurrency(reservation.paidAmount)}</TableCell>
                        <TableCell>
                          <Chip
                            label={paymentStatus}
                            color={getStatusColor(paymentStatus)}
                            size="small"
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Editar">
                            <IconButton
                              onClick={() => handleEditReservation(reservation)}
                              size="small"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Gerar Recibo">
                            <IconButton
                              onClick={() => handleGenerateReceipt(reservation)}
                              size="small"
                            >
                              <ReceiptIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Excluir">
                            <IconButton
                              onClick={() => handleDeleteClick(reservation)}
                              size="small"
                              color="error"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={Math.ceil(totalItems / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          showFirstButton
          showLastButton
        />
      </Box>

      <HotelReservationForm
        open={formOpen}
        onClose={handleCloseForm}
        onSubmit={handleSubmitReservation}
        initialData={editingReservation}
        loading={formLoading}
      />

      <Dialog
        open={deleteDialog.open}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirmar Exclusão
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem certeza que deseja excluir esta reserva? Esta ação não pode ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            disabled={formLoading}
            variant="contained"
            autoFocus
          >
            {formLoading ? (
              <>
                <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
                Excluindo...
              </>
            ) : (
              'Excluir'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default HotelReservationPage;