// HotelReservationForm.js
import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { useCurrencyInput } from '../../shared/utils/formatCurrency';

// Importing components
import { HotelInformation } from './components/HotelInformation';
import { PayerInformation } from './components/PayerInformation';
import { GuestsList } from './components/GuestsList';
import { PaymentsList } from './components/PaymentsList';
import { TotalValues } from './components/TotalValues';
import { FormActions } from './components/FormActions';

const HotelReservationForm = ({
    open,
    onClose,
    onSubmit,
    initialData,
    loading = false
}) => {
    // Estado inicial padrão
    const defaultFormState = {
        // Hotel Information
        hotelName: '',
        hotelId: '',
        hotelAddress: '',
        hotelPhone: '',
        roomType: '',
        pensionType: '',
        checkInDate: '',
        checkInTime: '14:00',
        checkOutDate: '',
        checkOutTime: '11:00',

        // Payer Information
        payerName: '',
        payerDocument: '',
        payerEmail: '',
        payerRG: '',
        isForeignPayer: false,
        payerPassport: '',

        // Financial Information
        totalAmount: '',
        paidAmount: '',
        remainingAmount: '',

        // Lists
        guests: [{
            type: 'Adulto',
            name: '',
            birthDate: ''
        }],
        payments: [{
            date: '',
            amount: '',
            paymentMethod: ''
        }],

        totalAmount: '0.00',
        paidAmount: '0.00',
        remainingAmount: '0.00',
    };

    // Estados
    const [formData, setFormData] = useState(defaultFormState);
    const [errors, setErrors] = useState({});
    const [paymentCurrencyInputs, setPaymentCurrencyInputs] = useState([
        { value: '0,00' }
    ]);

    // Hook para gerenciar o valor total
    const {
        value: amountValue,
        handleChange: handleAmountChange,
        handleBlur: handleAmountBlur,
        toNumber: getAmountNumber,
        setValue: setAmountValue
    } = useCurrencyInput(formData.totalAmount);

    // Função para limpar o formulário
    const resetForm = () => {
        setFormData(defaultFormState);
        setAmountValue('');
        setPaymentCurrencyInputs([{ value: '0,00' }]);
        setErrors({});
    };

    // Efeito para carregar dados iniciais ou limpar o formulário
    useEffect(() => {
        if (open) {
            if (initialData) {
                // Se tiver dados iniciais, preenche o formulário
                setFormData({
                    hotelName: initialData.hotelName || '',
                    hotelId: initialData.hotelId || '',
                    hotelAddress: initialData.hotelAddress || '',
                    hotelPhone: initialData.hotelPhone || '',
                    roomType: initialData.roomType || '',
                    pensionType: initialData.pensionType || '',
                    checkInDate: initialData.checkInDate || '',
                    checkInTime: initialData.checkInTime || '14:00',
                    checkOutDate: initialData.checkOutDate || '',
                    checkOutTime: initialData.checkOutTime || '11:00',
                    payerName: initialData.payerName || '',
                    payerEmail: initialData.payerEmail || '',
                    payerDocument: initialData.payerDocument || '',
                    payerRG: initialData.payerRG || '',
                    isForeignPayer: initialData.isForeignPayer || false,
                    payerPassport: initialData.payerPassport || '',
                    totalAmount: initialData.totalAmount || '',
                    paidAmount: initialData.paidAmount || '',
                    remainingAmount: initialData.remainingAmount || '',
                    guests: initialData.guests || [{ type: 'Adulto', name: '', birthDate: '' }],
                    payments: initialData.payments || [{ date: '', amount: '', paymentMethod: '' }]
                });

                setAmountValue(initialData.totalAmount?.toString() || '');

                const newPaymentInputs = initialData.payments?.map(payment => ({
                    value: payment.amount ?
                        Number(payment.amount).toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }) :
                        '0,00'
                })) || [{ value: '0,00' }];

                setPaymentCurrencyInputs(newPaymentInputs);
            } else {
                // Se não tiver dados iniciais, limpa o formulário
                resetForm();
            }
        }
    }, [open, initialData, setAmountValue]);

    // Função memoizada para calcular totais
    const calculateTotals = useCallback(() => {
        const totalPaid = formData.payments.reduce((sum, payment) => {
            const amount = parseFloat(payment.amount) || 0;
            return sum + amount;
        }, 0);

        const total = getAmountNumber() || 0;
        const remaining = total - totalPaid;

        // Atualiza apenas se os valores mudaram
        if (totalPaid !== parseFloat(formData.paidAmount) ||
            remaining !== parseFloat(formData.remainingAmount)) {
            setFormData(prev => ({
                ...prev,
                paidAmount: totalPaid.toFixed(2),
                remainingAmount: remaining.toFixed(2)
            }));
        }
    }, [formData.payments, getAmountNumber]);

    // Efeito para calcular valores totais
    useEffect(() => {
        calculateTotals();
    }, [calculateTotals]);

    // Handlers para pagamentos
    const addPayment = () => {
        setFormData(prev => ({
            ...prev,
            payments: [...prev.payments, { date: '', amount: '', paymentMethod: '' }]
        }));
        setPaymentCurrencyInputs(prev => [...prev, { value: '0,00' }]);
    };

    const removePayment = (index) => {
        setFormData(prev => ({
            ...prev,
            payments: prev.payments.filter((_, i) => i !== index)
        }));
        setPaymentCurrencyInputs(prev => prev.filter((_, i) => i !== index));
    };

    // Handlers para hóspedes
    const addGuest = () => {
        setFormData(prev => ({
            ...prev,
            guests: [...prev.guests, { type: 'Adulto', name: '', birthDate: '' }]
        }));
    };

    const removeGuest = (index) => {
        setFormData(prev => ({
            ...prev,
            guests: prev.guests.filter((_, i) => i !== index)
        }));
    };

    // Handlers gerais
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleGuestChange = (index, field, value) => {
        const newGuests = [...formData.guests];
        newGuests[index] = {
            ...newGuests[index],
            [field]: value
        };
        setFormData(prev => ({
            ...prev,
            guests: newGuests
        }));
    };

    const handlePaymentChange = (index, field, value) => {
        if (field === 'amount') {
            // Atualiza o valor do input de moeda
            const newPaymentInputs = [...paymentCurrencyInputs];
            newPaymentInputs[index] = {
                ...newPaymentInputs[index],
                value: value
            };
            setPaymentCurrencyInputs(newPaymentInputs);

            // Atualiza o valor no estado do pagamento
            const newPayments = [...formData.payments];
            newPayments[index] = {
                ...newPayments[index],
                [field]: value
            };
            setFormData(prev => ({
                ...prev,
                payments: newPayments
            }));
        } else {
            // Handle other fields normally
            const newPayments = [...formData.payments];
            newPayments[index] = {
                ...newPayments[index],
                [field]: value
            };
            setFormData(prev => ({
                ...prev,
                payments: newPayments
            }));
        }
    };

    // Validação do formulário
    const isFormValid = () => {
        if (!formData.hotelName ||
            !formData.checkInDate ||
            !formData.checkOutDate ||
            !formData.payerName ||
            (formData.isForeignPayer ? !formData.payerPassport : !formData.payerDocument) ||
            !amountValue) {
            return false;
        }

        return true;
    };

    const convertCurrencyToNumber = (currencyString) => {
        if (!currencyString) return 0;
        const numericString = currencyString.replace(/\./g, '').replace(',', '.');
        return parseFloat(numericString) || 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const submitData = {
            ...formData,
            totalAmount: convertCurrencyToNumber(amountValue),
            payments: formData.payments.map((payment, index) => ({
                ...payment,
                // Aqui usamos o valor já formatado do estado paymentCurrencyInputs
                amount: convertCurrencyToNumber(paymentCurrencyInputs[index].value)
            }))
        };

        onSubmit(submitData);
    };

    const handleSaveAndGenerateReceipt = () => {
        const submitData = {
            ...formData,
            totalAmount: convertCurrencyToNumber(amountValue),
            payments: formData.payments.map((payment, index) => ({
                ...payment,
                amount: convertCurrencyToNumber(paymentCurrencyInputs[index].value)
            }))
        };

        onSubmit(submitData, true);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <Typography variant="h6" gutterBottom>
                        {initialData ? 'Editar Reserva' : 'Nova Reserva'}
                    </Typography>

                    <Grid container spacing={2}>
                        {/* Hotel Information */}
                        <HotelInformation
                            formData={formData}
                            handleInputChange={handleInputChange}
                        />

                        {/* Payer Information */}
                        <PayerInformation
                            formData={formData}
                            handleInputChange={handleInputChange}
                        />

                        {/* Guests List */}
                        <GuestsList
                            guests={formData.guests}
                            handleGuestChange={handleGuestChange}
                            addGuest={addGuest}
                            removeGuest={removeGuest}
                        />

                        {/* Payments List */}
                        <PaymentsList
                            payments={formData.payments}
                            paymentCurrencyInputs={paymentCurrencyInputs}
                            handlePaymentChange={handlePaymentChange}
                            setPaymentCurrencyInputs={setPaymentCurrencyInputs}
                            setFormData={setFormData}
                            addPayment={addPayment}
                            removePayment={removePayment}
                        />

                        {/* Total Values */}
                        <TotalValues
                            amountValue={amountValue}
                            handleAmountChange={handleAmountChange}
                            handleAmountBlur={handleAmountBlur}
                            formData={formData}
                            setFormData={setFormData}
                            setAmountValue={setAmountValue}
                        />
                    </Grid>

                    {/* Form Actions */}
                    <FormActions
                        onClose={onClose}
                        loading={loading}
                        isFormValid={isFormValid}
                        initialData={initialData}
                        onSaveAndGenerateReceipt={handleSaveAndGenerateReceipt}
                    />
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default HotelReservationForm;