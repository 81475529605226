import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Chip,
  Divider,
  useTheme,
  Grid
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { formatDate } from '../../../shared/utils/utils';

const TravelInfo = ({ travel }) => {
  const theme = useTheme();

  const statusStyles = {
    'Cancelada': { color: theme.palette.error.light, text: 'Cancelada' },
    'Em andamento': { color: theme.palette.success.main, text: 'Em andamento' },
    'Próxima': { color: theme.palette.info.main, text: 'Próxima' },
    'Encerrada': { color: theme.palette.grey[500], text: 'Encerrada' },
    'Criada': { color: theme.palette.primary.light, text: 'Criada' },
    'Indefinido': { color: theme.palette.grey[500], text: 'Indefinido' }
  };

  if (!travel) return null;

  const { id, origem, destino, dataIda, dataRetorno, horarioIda, horarioRetorno, status, veiculo } = travel;
  const statusData = statusStyles[status] || statusStyles['Indefinido'];

  return (
    <Accordion
      sx={{
        '& .MuiAccordionSummary-content': {
          margin: '8px 0',
        },
        boxShadow: 'none',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '8px',
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="travel-info-content"
        id={`travel-info-${id}`}
        sx={{
          minHeight: '48px',
          '&.Mui-expanded': {
            minHeight: '48px',
          },
        }}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={3}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Chip
                label={statusData.text}
                size="small"
                sx={{
                  backgroundColor: statusData.color,
                  height: '24px'
                }}
                color="primary"
              />
              {travel.identificador && (
                <Typography variant="caption" color="textSecondary">
                  ID: {travel.identificador}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
                <LocationOnIcon sx={{ fontSize: 16, mr: 0.5 }} color="primary" />
                {origem}
              </Typography>
              <ArrowForwardIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
              <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
                <LocationOnIcon sx={{ fontSize: 16, mr: 0.5, color: theme.palette.error.light }} />
                {destino}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
              <DateRangeIcon sx={{ fontSize: 16, mr: 0.5 }} />
              {formatDate(dataIda)}
              {horarioIda && (
                <>
                  <AccessTimeIcon sx={{ fontSize: 16, mx: 0.5 }} />
                  {horarioIda}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails sx={{ pt: 0, pb: 2 }}>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant="body2" color="text.secondary">
                Ida: {formatDate(dataIda)} {horarioIda && `- ${horarioIda}`}
              </Typography>
              {dataRetorno && (
                <Typography variant="body2" color="text.secondary">
                  Retorno: {formatDate(dataRetorno)} {horarioRetorno && `- ${horarioRetorno}`}
                </Typography>
              )}
            </Box>
          </Grid>
          {veiculo && (
            <Grid item xs={12} md={6}>
              <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
                <DirectionsBusIcon sx={{ fontSize: 16, mr: 0.5 }} />
                {veiculo.identificadorVeiculo} - {veiculo.placa}
              </Typography>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default TravelInfo;