import { formatCPF, formatDate, formatDateForFilename, normalizeValue, formatTelefone } from './utils';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logoTravell from '../../assets/logo-travell-combr.png';
import { auth, db } from '../../firebaseConfig';
import { getDoc, doc as firestoreDoc } from 'firebase/firestore';
import { getAgencyLogo } from '../../pages/UpdateAgency/services/LogoService';
import { convertSvgToPng } from './convertSvgToPng';

export const exportOrdersToPDF = async (travel, orders, passengers) => {
  // Initialize PDF document
  const doc = new jsPDF('landscape', 'pt');
  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;
  let startY = 70;

  // Get agency information
  const user = auth.currentUser;
  const userRef = firestoreDoc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  const agencyRef = firestoreDoc(db, 'agencies', userDoc.data().agencyId);
  const agencyDoc = await getDoc(agencyRef);
  const agencyData = agencyDoc.data();

  // Get agency logo
  let logoData = null;
  try {
    logoData = await getAgencyLogo(userDoc.data().agencyId);
  } catch (error) {
    console.warn('Unable to fetch agency logo:', error);
  }

  // Load Travell logo
  const travellImg = new Image();
  travellImg.src = logoTravell;

  await new Promise((resolve, reject) => {
    travellImg.onload = resolve;
    travellImg.onerror = reject;
  });

  // Utility functions
  const getImageFormat = (base64String, contentType) => {
    if (contentType) {
      if (contentType.includes('jpeg') || contentType.includes('jpg')) return 'JPEG';
      if (contentType.includes('png')) return 'PNG';
      if (contentType.includes('svg')) return 'SVG';
    }

    if (base64String.startsWith('data:image/jpeg') || base64String.startsWith('data:image/jpg')) return 'JPEG';
    if (base64String.startsWith('data:image/png')) return 'PNG';
    if (base64String.startsWith('data:image/svg')) return 'SVG';

    return 'PNG';
  };

  const truncateText = (text, maxLength) => {
    if (!text) return 'Não informado';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  // Handle agency logo display
  if (logoData && logoData.base64) {
    try {
      let imageData = logoData.base64;
      let imageFormat = getImageFormat(imageData, logoData.contentType);

      if (imageFormat === 'SVG') {
        imageData = await convertSvgToPng(logoData.base64);
        imageFormat = 'PNG';
      }

      const img = new Image();
      img.src = imageData;

      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = (error) => {
          console.error('Error loading image:', error);
          reject(error);
        };
      });

      // Calculate logo dimensions (max height 60px for landscape)
      const maxHeight = 60;
      const aspectRatio = img.width / img.height;
      const logoHeight = Math.min(maxHeight, img.height);
      const logoWidth = logoHeight * aspectRatio;

      // Position agency logo at top right
      const logoX = pageWidth - logoWidth - 40;
      const logoY = 15;

      doc.addImage(
        imageData,
        imageFormat,
        logoX,
        logoY,
        logoWidth,
        logoHeight
      );

      // Add agency name and phone
      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      const agencyPhone = agencyData.phone ? formatTelefone(agencyData.phone) : '';
      const agencyText = agencyData.name + (agencyPhone ? ` | ${agencyPhone}` : '');
      doc.text(agencyText, 20, 20);

    } catch (error) {
      console.error('Error adding agency logo to PDF:', error);
    }
  } else {
    // If no logo, still display agency name and phone
    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    const agencyPhone = agencyData.phone ? formatTelefone(agencyData.phone) : '';
    const agencyText = agencyData.name + (agencyPhone ? ` | ${agencyPhone}` : '');
    doc.text(agencyText, 20, 20);
  }

  const columns = [
    { title: "Pedido ID", dataKey: "orderId" },
    { title: "Assentos", dataKey: "numerosAssentos" },
    { title: "Nome do Pagador", dataKey: "nomePagador" },
    { title: "CPF do Pagador", dataKey: "cpfPagador" },
    { title: "RG do Pagador", dataKey: "rgPagador" },
    { title: "Valor Total", dataKey: "valorTotal" },
    { title: "Método de Pagamento", dataKey: "metodoPagamento" },
    { title: "Status", dataKey: "status" },
    { title: "Informações Adicionais", dataKey: "informacoesAdicionais" }
  ];

  const filteredOrders = orders.filter(order => order.status !== 'Cancelada');

  const rows = filteredOrders.map(order => {
    const assentos = order.reservations
      .map(reservation => reservation.numeroAssento)
      .filter(numeroAssento => numeroAssento !== null)
      .join(', ');
    const informacoesAdicionais = order.reservations
      .map(reservation => reservation.detalhesPagamento?.informacoesAdicionais)
      .filter(info => info)
      .join('; ');

    const nomePagador = order.detalhesPagamento?.nomePagador || 'Não informado';
    const cpfPagador = order.detalhesPagamento?.passaportePagador
      ? `Passaporte: ${order.detalhesPagamento.passaportePagador}`
      : order.detalhesPagamento?.cpfPagador
        ? formatCPF(order.detalhesPagamento.cpfPagador)
        : 'Não informado';

    const rgPagador = order.detalhesPagamento?.passaportePagador
      ? 'Não aplicável'
      : order.detalhesPagamento?.rgPagador || 'Não informado';

    const valorTotal = order.detalhesPagamento?.valorTotal
      ? `R$ ${normalizeValue(order.detalhesPagamento.valorTotal)}`
      : 'Não informado';
    const metodoPagamento = order.detalhesPagamento?.metodoPagamento || 'Não informado';

    return {
      orderId: order.id,
      numerosAssentos: assentos,
      nomePagador: nomePagador,
      cpfPagador: cpfPagador,
      rgPagador: rgPagador,
      valorTotal: valorTotal,
      metodoPagamento: metodoPagamento,
      status: order.status,
      informacoesAdicionais: truncateText(informacoesAdicionais, 50)
    };
  });

  // Add travel details
  doc.setFontSize(14);
  doc.setFont('helvetica', 'bold');
  doc.text('Detalhes da Viagem', 20, 35);

  doc.setFontSize(10);
  doc.setFont('helvetica', 'normal');

  const infoLine1 = truncateText(
    `Identificador: ${travel?.identificador || 'Não informado'} | Origem: ${travel?.origem || 'Não informado'} | Destino: ${travel?.destino || 'Não informado'}`,
    120
  );
  doc.text(infoLine1, 20, 50);

  const infoLine2 = truncateText(
    `Ida: ${formatDate(travel?.dataIda) || 'Não informado'} às ${travel?.horarioIda || 'Não informado'} | ${travel?.somenteIda
      ? 'Somente Ida'
      : `Volta: ${formatDate(travel?.dataRetorno) || 'Não informado'} às ${travel?.horarioRetorno || 'Não informado'}`
    }`,
    120
  );
  doc.text(infoLine2, 20, 65);

  if (travel?.veiculo) {
    const infoLine3 = truncateText(
      `Veículo: ${travel.veiculo.identificadorVeiculo || 'Não informado'} - ${travel.veiculo.placa || 'Não informado'} (${travel.veiculo.empresa || 'Não informado'
      })${travel?.informacoesAdicionais ? ` | Info: ${truncateText(travel.informacoesAdicionais, 50)}` : ''}`,
      120
    );
    doc.text(infoLine3, 20, 80);
    startY = 85;
  }

  // Generate table with orders
  doc.autoTable({
    head: [columns.map(col => col.title)],
    body: rows.map(row => columns.map(col => row[col.dataKey])),
    startY: startY,
    theme: 'striped',
    margin: { top: 10, bottom: 30, left: 20, right: 20 },
    styles: { fontSize: 10, halign: 'center' },
    headStyles: { fillColor: [25, 118, 210], textColor: [255, 255, 255] },
    alternateRowStyles: { fillColor: [240, 240, 240] },
    columnStyles: {
      orderId: { cellWidth: 50 },
    },
    didDrawPage: function (data) {
      // Add page numbers
      doc.setFontSize(8);
      doc.setTextColor(40);
      let str = 'Página ' + doc.internal.getNumberOfPages();
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + doc.internal.getNumberOfPages();
      }
      doc.text(str, data.settings.margin.left, pageHeight - 10);

      // Add Travell logo on each page (smaller and at the bottom)
      const travellLogoWidth = 90;
      const travellLogoHeight = 25;
      const travellLogoX = pageWidth - travellLogoWidth - 20;
      const travellLogoY = pageHeight - travellLogoHeight - 10;

      doc.addImage(
        travellImg,
        'PNG',
        travellLogoX,
        travellLogoY,
        travellLogoWidth,
        travellLogoHeight
      );
      doc.link(travellLogoX, travellLogoY, travellLogoWidth, travellLogoHeight, { url: 'https://travell.com.br' });
    },
  });

  // Save the PDF
  const identifier = travel?.identificador || 'sem-identificador';
  const origem = travel?.origem || 'sem-origem';
  const destino = travel?.destino || 'sem-destino';
  const dataIda = formatDateForFilename(travel?.dataIda || 'sem-data-ida');
  const dataRetorno = travel?.somenteIda ? 'somente-ida' : formatDateForFilename(travel?.dataRetorno || 'sem-data-retorno');
  const filename = `pedidos_${identifier}_de_${origem}_na_data_${dataIda}_a_${destino}_com_volta_${dataRetorno}.pdf`;

  doc.save(filename);
};