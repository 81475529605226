import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, CircularProgress, Box, Typography, IconButton, TextField, Button, Snackbar, Alert, Pagination, InputAdornment, FormControl, InputLabel, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Card, Fade, Tabs, Tab } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearIcon from '@mui/icons-material/Clear';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';

import TravelInfo from './components/TravelInfo';
import OrderCard from '../../components/Order/OrderCard';
import ReservationCard from '../../components/Reservation/ReservationCard';
import OrderList from '../../components/Order/OrderList';
import ReservationList from '../../components/Reservation/ReservationList';
import OrderDetails from '../../components/Order/OrderDetails';
import ReservationDetails from '../../components/Reservation/ReservationDetails';
import TravelOrderReservationHelp from './helps/TravelOrderReservationHelp';
import PDFOptionsDialog from './components/PDFOptionsDialog';
import TravelLoading from '../../shared/common/TravelLoading';

import { usePermissions } from '../../hooks/usePermissions';
import { getSavedViewType, saveViewType } from '../../shared/utils/localStorageUtils';
import { getTravelById } from '../Travel/services/TravelService';
import { getSortedReservationsByTravelId, getSortedOrdersByTravelId, cancelOrder, cancelReservation, calculateTotalsByTravelId, updateReservation, getReservedSeats } from '../Allocation/services/OrderService';
import { getAllPassengersWithoutStatusFilter } from '../Passenger/services/PassengerService';
import { exportToPDF as exportReservationsToPDF } from '../../shared/utils/ReservationsPDF';
import { getLayoutById } from '../LayoutPage/services/LayoutService';
import { getVehicleById } from '../Travel/services/TravelService';
import SeatChangeDialog from '../Allocation/components/SeatChangeDialog';
import { exportOrdersToPDF } from '../../shared/utils/OrdersPDF';
import { formatCPF, normalizeValue } from '../../shared/utils/utils';
import { useMasterPassword } from '../../shared/hooks/useMasterPassword';

const TravelOrdersReservationsPage = () => {
  const { checkPermission } = usePermissions();
  const { travelId } = useParams();
  const navigate = useNavigate();
  const [travel, setTravel] = useState(null);
  const [orders, setOrders] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [passengers, setPassengers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [loadingReservations, setLoadingReservations] = useState(false);
  const [reservationsLoaded, setReservationsLoaded] = useState(false);
  const [ordersLoaded, setOrdersLoaded] = useState(false);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [paymentStatusFilter, setPaymentStatusFilter] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [cancelOrderId, setCancelOrderId] = useState(null);
  const [cancelReservationId, setCancelReservationId] = useState(null);
  const [masterPassword, setMasterPassword] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [totalReceivable, setTotalReceivable] = useState(0);
  const [totalReceived, setTotalReceived] = useState(0);
  const [pdfOptionsDialogOpen, setPdfOptionsDialogOpen] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [viewMode, setViewMode] = useState(getSavedViewType());
  const [openSeatSelectionDialog, setOpenSeatSelectionDialog] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [layoutAndar1, setLayoutAndar1] = useState([]);
  const [layoutAndar2, setLayoutAndar2] = useState([]);
  const [reservedSeats, setReservedSeats] = useState([]);
  const [sortConfig, setSortConfig] = useState({ type: 'seatNumber', order: 'asc' });
  const theme = useTheme();
  const itemsPerPage = 12;
  const {
    isVerifying,
    error: masterPasswordError,
    clearError,
    masterPasswordActive,
    showPassword,
    togglePasswordVisibility,
    verifyMasterPassword
  } = useMasterPassword();

  const canViewTravel = checkPermission('travels', 'view');
  const canViewOrders = checkPermission('orders', 'view');
  const canEditOrders = checkPermission('orders', 'edit');
  const canCancelOrders = checkPermission('orders', 'cancel');


  useEffect(() => {
    const fetchTravelDetailsAndPassengers = async () => {
      try {
        const travelDetails = await getTravelById(travelId);
        const fetchedPassengers = await getAllPassengersWithoutStatusFilter();
        setTravel(travelDetails);
        setPassengers(fetchedPassengers);
        setError('');
      } catch (err) {
        setError('Falha ao carregar detalhes da viagem e passageiros: ' + err.message);
      }
    };

    const fetchTotals = async () => {
      try {
        const { totalReceivable, totalReceived } = await calculateTotalsByTravelId(travelId);
        setTotalReceivable(totalReceivable);
        setTotalReceived(totalReceived);
      } catch (err) {
        console.error('Erro ao calcular totais:', err);
      }
    };

    fetchTravelDetailsAndPassengers();
    fetchTotals();
  }, [travelId]);

  useEffect(() => {
    if (tabIndex === 0 && !reservationsLoaded) {
      const fetchReservations = async () => {
        setLoadingReservations(true);
        try {
          const fetchedReservations = await getSortedReservationsByTravelId(travelId, sortConfig);
          setReservations(fetchedReservations);
          setFilteredData(fetchedReservations);
          setReservationsLoaded(true);
          setError('');
        } catch (err) {
          setError('Falha ao carregar reservas: ' + err.message);
        } finally {
          setLoadingReservations(false);
        }
      };
      fetchReservations();
    }
  }, [tabIndex, travelId, reservationsLoaded, sortConfig]);

  useEffect(() => {
    if (tabIndex === 1 && !ordersLoaded) {
      const fetchOrders = async () => {
        setLoadingOrders(true);
        try {
          const fetchedOrders = await getSortedOrdersByTravelId(travelId);
          setOrders(fetchedOrders);
          setFilteredData(fetchedOrders);
          setOrdersLoaded(true);
          setError('');
        } catch (err) {
          setError('Falha ao carregar pedidos: ' + err.message);
        } finally {
          setLoadingOrders(false);
        }
      };
      fetchOrders();
    }
  }, [tabIndex, travelId, ordersLoaded]);

  useEffect(() => {
    const lowercasedFilter = searchTerm.toLowerCase();

    const filteredReservations = reservations.filter(reservation => {
      const passenger = passengers.find(p => p.id === reservation.passengerId) || {};
      return (
        (passenger.nome?.toLowerCase().includes(lowercasedFilter) ||
          passenger.cpf?.toLowerCase().includes(lowercasedFilter) ||
          passenger.rg?.toLowerCase().includes(lowercasedFilter) ||
          passenger.passaporte?.toLowerCase().includes(lowercasedFilter) ||
          reservation.orderId.toLowerCase().includes(lowercasedFilter)) &&
        (paymentStatusFilter === '' || reservation.status === paymentStatusFilter)
      );
    });

    const filteredOrders = orders.filter(order => {
      return (
        (order.id.toLowerCase().includes(lowercasedFilter) ||
          order.reservations.some(reservation => {
            const passenger = passengers.find(p => p.id === reservation.passengerId) || {};
            return (
              passenger.nome?.toLowerCase().includes(lowercasedFilter) ||
              passenger.cpf?.toLowerCase().includes(lowercasedFilter) ||
              passenger.passaporte?.toLowerCase().includes(lowercasedFilter) ||
              passenger.rg?.toLowerCase().includes(lowercasedFilter)
            );
          })) &&
        (paymentStatusFilter === '' || order.status === paymentStatusFilter)
      );
    });

    setFilteredData(tabIndex === 0 ? filteredReservations : filteredOrders);
  }, [searchTerm, orders, reservations, passengers, paymentStatusFilter, tabIndex]);

  const handleGoBack = () => navigate(`/viagens/${travelId}`);

  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  const handleClearSearch = () => setSearchTerm('');

  const handlePaymentStatusFilterChange = (e) => setPaymentStatusFilter(e.target.value);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handlePageChange = (event, value) => setCurrentPage(value);

  const handleOpenPDFOptionsDialog = () => setPdfOptionsDialogOpen(true);
  const handleClosePDFOptionsDialog = () => setPdfOptionsDialogOpen(false);

  const handleGeneratePDF = (selectedFields = null, sortOrder = "numeroAssento") => {
    setPdfOptionsDialogOpen(false);

    const activeReservations = filteredData
      .filter((reservation) => reservation.status !== 'Cancelada')
      .map((reservation) => {
        const passenger = passengers.find((p) => p.id === reservation.passengerId) || {};
        return {
          ...reservation,
          passengerNome: passenger.nome || "Não informado",
        };
      });


    const sortedReservations = activeReservations.sort((a, b) => {
      if (sortOrder === "numeroAssento") {
        return (a.numeroAssento || 0) - (b.numeroAssento || 0);
      }
      if (sortOrder === "passengerNome") {
        return (a.passengerNome || "").localeCompare(b.passengerNome || "");
      }
      return 0;
    });

    if (tabIndex === 0) {
      if (travel && sortedReservations.length > 0) {
        exportReservationsToPDF(travel, sortedReservations, passengers, selectedFields);
      } else {
        setSnackbarMessage('Nenhuma reserva ativa encontrada para exportação.');
        setSnackbarOpen(true);
      }
    } else {
      if (travel && filteredData.length > 0) {

        const defaultOrderFields = {
          orderId: true,
          numerosAssentos: true,
          nomePagador: true,
          cpfPagador: true,
          rgPagador: true,
          valorTotal: true,
          metodoPagamento: true,
          status: true,
          informacoesAdicionais: true,
        };

        const sortedOrders = filteredData.sort((a, b) => {
          if (sortOrder === "numeroAssento") {
            return (a.numerosAssentos || "").localeCompare(b.numerosAssentos || "");
          }
          if (sortOrder === "passengerNome") {
            return (a.nomePagador || "").localeCompare(b.nomePagador || "");
          }
          return 0;
        });

        exportOrdersToPDF(travel, sortedOrders, passengers, defaultOrderFields);
      } else {
        setSnackbarMessage('Nenhum pedido encontrado para exportação.');
        setSnackbarOpen(true);
      }
    }
  };

  const handleEditReservation = (reservation, orderId) => {

    navigate(`/viagens/${reservation.travelId}/pedido/${orderId}`, {
      state: {
        editingReservation: reservation,
        editingOrderId: orderId,
      },
    });
  };

  const handleEditOrder = (order) => {
    navigate(`/viagens/${order.travelId}/pedido/${order.id}`, {
      state: {
        editingOrderId: order.id,
        travelId: order.travelId,
        orderDetails: order,
      },
    });
  };

  const handleCancelOrder = (orderId) => {
    setCancelOrderId(orderId);
    setCancelDialogOpen(true);
  };

  const handleCancelReservation = (reservationId, orderId) => {
    setCancelOrderId(orderId);
    setCancelReservationId(reservationId);
    setCancelDialogOpen(true);
  };

  const confirmCancelOrder = async () => {
    setCancelLoading(true);
    try {
      if (masterPasswordActive) {
        const isValid = await verifyMasterPassword(masterPassword);
        if (!isValid) {
          setSnackbarMessage('Senha master incorreta');
          setSnackbarOpen(true);
          setCancelLoading(false);
          return;
        }
      }

      if (cancelReservationId) {
        await cancelReservation(travelId, cancelOrderId, cancelReservationId);
        setSnackbarMessage('Reserva cancelada com sucesso.');
      } else {
        await cancelOrder(travelId, cancelOrderId);
        setSnackbarMessage('Pedido cancelado com sucesso.');
      }

      setSnackbarOpen(true);
      setReservationsLoaded(false);
      setOrdersLoaded(false);
      setCancelDialogOpen(false);
      setCancelReservationId(null);
      setMasterPassword('');
      clearError();
    } catch (err) {
      setSnackbarMessage(err.message.includes('master') ? 'Senha master incorreta' : 'Erro ao cancelar: ' + err.message);
      setSnackbarOpen(true);
    } finally {
      setCancelLoading(false);
    }
  };
  const handleCloseCancelDialog = () => {
    setCancelDialogOpen(false);
    setCancelReservationId(null);
    setCancelOrderId(null);
    setMasterPassword('');
    clearError();
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  const currentItems = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return filteredData.slice(indexOfFirstItem, indexOfLastItem);
  }, [filteredData, currentPage, itemsPerPage]);

  const handleOpenModal = (data) => {
    setSelectedData(data);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedData(null);
  };

  const fetchLayoutByVehicle = useCallback(async (vehicleId) => {
    try {
      const vehicleData = await getVehicleById(vehicleId);
      if (vehicleData?.layoutId) {
        const layoutData = await getLayoutById(vehicleData.layoutId);
        setLayoutAndar1(layoutData.firstFloor || []);
        setLayoutAndar2(layoutData.secondFloor || []);
      }
    } catch (err) {
      console.error('Erro ao buscar layout do veículo:', err);
    }
  }, []);

  const fetchSeatData = useCallback(async () => {
    try {
      if (travel?.veiculoId) {
        await fetchLayoutByVehicle(travel.veiculoId);
        const allReservedSeats = await getReservedSeats(travelId);
        setReservedSeats(allReservedSeats.filter((res) => res.status !== 'Cancelada'));
      }
    } catch (error) {
      console.error('Erro ao buscar dados de assentos:', error);
    }
  }, [travel, travelId, fetchLayoutByVehicle]);

  // Adicione este useEffect após os outros useEffects
  useEffect(() => {
    if (travel?.veiculoId) {
      fetchSeatData();
    }
  }, [travel, fetchSeatData]);

  const handleOpenSeatChange = (reservation) => {
    setSelectedReservation(reservation);
    setOpenSeatSelectionDialog(true);
  };

  const handleCloseSeatChange = () => {
    setOpenSeatSelectionDialog(false);
    setSelectedReservation(null);
  };

  const handleSeatChange = async (newSeatNumber) => {
    if (!selectedReservation) return;

    try {
      await updateReservation(selectedReservation.id, {
        ...selectedReservation,
        numeroAssento: newSeatNumber,
      });

      setReservationsLoaded(false);
      setOrdersLoaded(false);

      setSnackbarMessage('Assento alterado com sucesso!');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Erro ao atualizar assento:', error);
      setSnackbarMessage('Erro ao atualizar assento. Por favor, tente novamente.');
      setSnackbarOpen(true);
    } finally {
      handleCloseSeatChange();
    }
  };

  const renderModalContent = useCallback(() => {
    if (!selectedData) return null;
    return tabIndex === 0
      ? <ReservationDetails reservation={selectedData} passengers={passengers} travel={travel} />
      : <OrderDetails order={selectedData} passengers={passengers} travel={travel} />;
  }, [selectedData, tabIndex, passengers]);

  const renderReservations = useCallback(() => (
    <Grid container spacing={2} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'stretch' }}>
      {currentItems.map((reservation) => (
        <Grid item xs={12} md={6} lg={4} key={reservation.id} sx={{ display: 'flex', flexDirection: 'column' }}>
          <ReservationCard
            reservation={reservation}
            passengers={passengers}
            travel={travel}
            hideTravelInfo
            onEditReservation={handleEditReservation}
            onCancelReservation={handleCancelReservation}
            onCardClick={handleOpenModal}
            onSeatChange={handleOpenSeatChange}
            canEdit={canEditOrders}
            canCancel={canCancelOrders}
            showSeatChange={true}
          />
        </Grid>
      ))}
    </Grid>
  ), [currentItems, passengers, travel, canEditOrders, canCancelOrders]);

  const renderOrders = useCallback(() => (
    <Grid container spacing={2}>
      {currentItems.map((order) => (
        <Grid item xs={12} md={6} lg={4} key={order.id}>
          <OrderCard
            order={order}
            travel={travel}
            hideTravelInfo
            onEditOrder={handleEditOrder}
            onCancelOrder={handleCancelOrder}
            onCardClick={handleOpenModal}
            canEdit={canEditOrders}
            canCancel={canCancelOrders}
          />
        </Grid>
      ))}
    </Grid>
  ), [currentItems, travel, canEditOrders, canCancelOrders]);

  const renderCancelDialogContent = () => {

    if (cancelReservationId) {
      const reservation = reservations.find(res => res.id === cancelReservationId);
      const passenger = passengers.find(p => p.id === reservation?.passengerId);

      return (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Informações do Passageiro</Typography>
          <Typography variant="body2">Nome: {passenger?.nome || 'Não informado'}</Typography>
          <Typography variant="body2">CPF: {passenger?.cpf ? formatCPF(passenger.cpf) : 'Não informado'}</Typography>
          <Typography variant="body2">RG: {passenger?.rg || 'Não informado'}</Typography>
          <Typography variant="body2">Passaporte: {passenger?.passaporte || 'Não informado'}</Typography>
        </Box>
      );
    }


    if (cancelOrderId && !cancelReservationId) {
      const order = orders.find(order => order.id === cancelOrderId);

      return (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Informações do Pagador</Typography>
          <Typography variant="body2">Pedido ID: {cancelOrderId}</Typography>
          <Typography variant="body2">Nome: {order?.detalhesPagamento?.nomePagador || 'Não informado'}</Typography>
          <Typography variant="body2">CPF: {order?.detalhesPagamento?.cpfPagador ? formatCPF(order.detalhesPagamento.cpfPagador) : 'Não informado'}</Typography>
          <Typography variant="body2">RG: {order?.detalhesPagamento?.rgPagador || 'Não informado'}</Typography>
        </Box>
      );
    }

    return null;
  };

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    saveViewType(mode);
  };

  return (
    <Box>
      <Box sx={{ gap: 2, marginBottom: 2, alignItems: 'center', flexWrap: 'wrap' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            mb: 3,
            flexWrap: 'wrap',
            gap: 2
          }}
        >
          <IconButton onClick={handleGoBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">
            Reservas e Pedidos
          </Typography>
          <TravelOrderReservationHelp />
        </Box>
        <TravelInfo travel={travel} />
      </Box>

      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: 2,
        mb: 2
      }}>
        <Box sx={{ flex: '1 1 auto' }}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Reservas" />
            <Tab label="Pedidos" />
          </Tabs>
        </Box>

        <Box sx={{
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap',
          alignItems: 'center'
        }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}>
            <MonetizationOnIcon sx={{ color: theme.palette.info.main }} />
            <Typography>
              Total a Receber: R$ {normalizeValue(totalReceivable.toFixed(2))}
            </Typography>
          </Box>

          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,

          }}>
            <CheckCircleOutlineIcon sx={{ color: theme.palette.success.main }} />
            <Typography>
              Total Recebido: R$ {normalizeValue(totalReceived.toFixed(2))}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2, alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        {tabIndex === 0 && (
          <FormControl variant="outlined" sx={{ flex: 1, minWidth: 200 }}>
            <InputLabel id="sort-config-label">Ordenar por</InputLabel>
            <Select
              labelId="sort-config-label"
              id="sort-config"
              value={`${sortConfig.type}-${sortConfig.order}`}
              onChange={(e) => {
                const [type, order] = e.target.value.split('-');
                setSortConfig({ type, order });
                setReservationsLoaded(false);
              }}
              label="Ordenar por"
            >
              <MenuItem value="seatNumber-asc">Número do assento</MenuItem>
              <MenuItem value="createdAt-asc">Data de criação (mais antigas)</MenuItem>
              <MenuItem value="createdAt-desc">Data de criação (mais recentes)</MenuItem>
            </Select>
          </FormControl>
        )}

        <TextField
          sx={{ flex: 2, minWidth: 200 }}
          label={tabIndex === 0 ? "Buscar Reservas" : "Buscar Pedidos"}
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder={tabIndex === 0 ? "Digite nome, documento ou ID do pedido" : "Digite nome, documento ou ID do pedido"}
          InputProps={{
            endAdornment: searchTerm ? (
              <InputAdornment position="end">
                <IconButton onClick={handleClearSearch} edge="end">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ) : null
          }}
        />

        {/* Rest of the component remains the same */}
        <FormControl variant="outlined" sx={{ flex: 1, maxWidth: 200 }}>
          <InputLabel id="payment-status-filter-label">Status de Pagamento</InputLabel>
          <Select
            labelId="payment-status-filter-label"
            id="payment-status-filter"
            value={paymentStatusFilter}
            onChange={handlePaymentStatusFilterChange}
            label="Status de Pagamento"
          >
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value="Pago">Pago</MenuItem>
            <MenuItem value="Pagamento Pendente">Pagamento pendente</MenuItem>
            <MenuItem value="Cancelada">Cancelada</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (tabIndex === 0) {
              handleOpenPDFOptionsDialog();
            } else {
              handleGeneratePDF();
            }
          }}
          sx={{ borderRadius: '50px' }}
        >
          {tabIndex === 0 ? "Exportar Reservas" : "Exportar Pedidos"}
        </Button>

        <PDFOptionsDialog
          open={pdfOptionsDialogOpen}
          onClose={handleClosePDFOptionsDialog}
          onGenerate={handleGeneratePDF}
        />

        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton
            onClick={() => handleViewModeChange('card')}
            color={viewMode === 'card' ? 'primary' : 'default'}
          >
            <ViewModuleIcon />
          </IconButton>
          <IconButton
            onClick={() => handleViewModeChange('table')}
            color={viewMode === 'table' ? 'primary' : 'default'}
          >
            <ViewListIcon />
          </IconButton>
        </Box>
      </Box>

      {tabIndex === 0 ? (
        loadingReservations ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <TravelLoading disableAlignItems />
          </Box>
        ) : (
          <Fade in={tabIndex === 0} timeout={500}>
            <div>
              {filteredData.length === 0 ? (
                <Box sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant="body1">Nenhuma reserva encontrada.</Typography>
                </Box>
              ) : viewMode === 'card' ? (
                renderReservations()
              ) : (
                <ReservationList
                  reservations={currentItems}
                  passengers={passengers}
                  onEditReservation={handleEditReservation}
                  onCancelReservation={handleCancelReservation}
                  onRowClick={handleOpenModal}
                  onSeatChange={handleOpenSeatChange}
                  showSeatChange={true}
                />
              )}
            </div>
          </Fade>
        )
      ) : (
        loadingOrders ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <TravelLoading disableAlignItems />
          </Box>
        ) : (
          <Fade in={tabIndex === 1} timeout={500}>
            <div>
              {filteredData.length === 0 ? (
                <Box sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant="body1">Nenhum pedido encontrado.</Typography>
                </Box>
              ) : viewMode === 'card' ? (
                renderOrders()
              ) : (
                <OrderList
                  orders={currentItems}
                  onEditOrder={handleEditOrder}
                  onCancelOrder={handleCancelOrder}
                  onRowClick={handleOpenModal}
                />
              )}
            </div>
          </Fade>
        )
      )}

      <Pagination
        count={Math.ceil(filteredData.length / itemsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        showFirstButton
        showLastButton
        sx={{ mt: 2 }}
      />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarMessage.includes('sucesso') ? 'success' : 'error'} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={cancelDialogOpen} onClose={handleCloseCancelDialog}>
        <DialogTitle>Confirmar Cancelamento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {cancelReservationId
              ? 'Tem certeza de que deseja cancelar esta reserva? Esta ação não pode ser desfeita.'
              : 'Tem certeza de que deseja cancelar este pedido? Todas as reservas deste pedido serão canceladas. Esta ação não pode ser desfeita.'}
          </DialogContentText>
          {renderCancelDialogContent()}
          {masterPasswordActive && (
            <TextField
              margin="normal"
              fullWidth
              label="Senha Master"
              type={showPassword ? 'text' : 'password'}
              value={masterPassword}
              onChange={(e) => setMasterPassword(e.target.value)}
              error={!!masterPasswordError}
              helperText={masterPasswordError}
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
              disabled={cancelLoading || isVerifying}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseCancelDialog}
            variant="outlined"
            disabled={cancelLoading || isVerifying}
            sx={{ borderRadius: '50px' }}
          >
            Voltar
          </Button>
          <Button
            onClick={confirmCancelOrder}
            variant="contained"
            color="error"
            disabled={(masterPasswordActive && !masterPassword) || cancelLoading || isVerifying}
            sx={{ color: 'white', borderRadius: '50px' }}
          >
            {cancelLoading || isVerifying ? (
              <CircularProgress size={24} />
            ) : (
              cancelReservationId ? 'Cancelar reserva' : 'Cancelar pedido'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <SeatChangeDialog
        open={openSeatSelectionDialog}
        onClose={handleCloseSeatChange}
        currentSeat={selectedReservation?.numeroAssento}
        layoutAndar1={layoutAndar1}
        layoutAndar2={layoutAndar2}
        reservedSeats={reservedSeats}
        allocatedSeats={currentItems.map(res => res.numeroAssento)}
        onSeatChange={handleSeatChange}
      />

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '80%', md: '60%' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxHeight: '90vh',
          overflowY: 'auto',
          borderRadius: 2,
        }}>
          <Typography variant="h5" gutterBottom>
            {tabIndex === 0 ? 'Detalhes da Reserva' : 'Detalhes do Pedido'}
          </Typography>
          {renderModalContent()}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
            <Button onClick={handleCloseModal} variant="outlined" color="primary" sx={{ borderRadius: '50px' }}>Fechar</Button>
          </Box>
        </Box>
      </Modal>
    </Box >
  );
};

export default TravelOrdersReservationsPage;
