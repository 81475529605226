import React from 'react';
import { Grid, TextField, Typography, Divider, FormControlLabel, Switch } from '@mui/material';
import { formatCPF, unformatCPF } from '../../../shared/utils/utils';

export const PayerInformation = ({ formData, handleInputChange }) => {
  const handleForeignGuestChange = (event) => {
    const isForeign = event.target.checked;
    handleInputChange({
      target: {
        name: 'isForeignPayer',
        value: isForeign
      }
    });

    handleInputChange({
      target: {
        name: 'payerDocument',
        value: ''
      }
    });
    handleInputChange({
      target: {
        name: 'payerRG',
        value: ''
      }
    });
    handleInputChange({
      target: {
        name: 'payerPassport',
        value: ''
      }
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <Divider sx={{ my: 2 }} />
        <Typography variant="subtitle1" gutterBottom>
          Informações do Pagador
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={formData.isForeignPayer || false}
              onChange={handleForeignGuestChange}
              name="isForeignPayer"
            />
          }
          label="Estrangeiro"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Nome do Pagador"
          name="payerName"
          value={formData.payerName}
          onChange={handleInputChange}
          required
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Email do Pagador"
          name="payerEmail"
          type="email"
          value={formData.payerEmail || ''}
          onChange={handleInputChange}
        />
      </Grid>



      {formData.isForeignPayer ? (
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Passaporte"
            name="payerPassport"
            value={formData.payerPassport || ''}
            onChange={handleInputChange}
            required
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="CPF do Pagador"
              name="payerDocument"
              value={formatCPF(formData.payerDocument)}
              onChange={(e) => handleInputChange({
                target: {
                  name: 'payerDocument',
                  value: unformatCPF(e.target.value)
                }
              })}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="RG do Pagador"
              name="payerRG"
              value={formData.payerRG || ''}
              onChange={handleInputChange}
            />
          </Grid>
        </>
      )}
    </>
  );
};