import React, { useEffect } from 'react';
import { Grid, TextField, Typography, Divider, InputAdornment } from '@mui/material';

export const TotalValues = ({
    amountValue,
    handleAmountChange,
    handleAmountBlur,
    formData,
    setFormData,
    setAmountValue  // Add this prop to allow updating the amount value
}) => {
    // Add an effect to format the total amount when it's first set
    useEffect(() => {
        if (formData.totalAmount) {
            // Convert the total amount to a formatted string
            const formattedAmount = Number(formData.totalAmount).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            
            // Update the amount value with the formatted string
            setAmountValue(formattedAmount);
        }
    }, [formData.totalAmount, setAmountValue]);

    return (
        <>
            <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Typography variant="subtitle1" gutterBottom>
                    Valores
                </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    label="Valor Total"
                    value={amountValue}
                    onChange={handleAmountChange}
                    onBlur={handleAmountBlur}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>
                    }}
                    required
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    label="Valor Pago"
                    name="paidAmount"
                    value={formData.paidAmount ? `${Number(formData.paidAmount).toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}` : 'R$ 0,00'}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        readOnly: true
                    }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    label="Valor Restante"
                    name="remainingAmount"
                    value={formData.remainingAmount ? `${Number(formData.remainingAmount).toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}` : 'R$ 0,00'}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>
                    }}
                    disabled
                />
            </Grid>
        </>
    );
};