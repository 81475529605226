import React from 'react';
import { Box, Button, CircularProgress } from '@mui/material';

export const FormActions = ({ onClose, loading, isFormValid, initialData, onSaveAndGenerateReceipt }) => {
    const handleSaveAndGenerateReceipt = (e) => {
        e.preventDefault();
        onSaveAndGenerateReceipt();
    };

    return (
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <Button
                onClick={onClose}
                variant="outlined"
                disabled={loading}
                sx={{ borderRadius: '50px' }}
            >
                {initialData ? 'Descartar Alterações' : 'Voltar'}
            </Button>
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                    onClick={handleSaveAndGenerateReceipt}
                    variant="outlined"
                    disabled={loading || !isFormValid()}
                    sx={{ borderRadius: '50px' }}
                >
                    {loading ? <CircularProgress size={20} /> : initialData ? 'Salvar e Gerar Recibo' : 'Salvar e Gerar Recibo'}
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={loading || !isFormValid()}
                    sx={{ borderRadius: '50px' }}
                >
                    {loading ? <CircularProgress size={20} /> : initialData ? 'Salvar' : 'Salvar'}
                </Button>
            </Box>
        </Box>
    );
};